// HeaderToaster.js
import React, { Component } from 'react';
import './HostGameView.scss';

export default class HeaderToaster extends Component {


    render() {

        return (

            <div className="header-toaster-tp-wrapper">
                <div className={this.props.wobble ? 'header-toaster-wrapper wobble' : 'header-toaster-wrapper'}>
                    <div className="header-toaster-container">
                        <h1>{this.props.message}</h1>
                        {this.props.message !== "What's the connection?" && this.props.timer && this.props.timer !== 0 ? (
                            <div className={`header-toaster-timer ${this.props.isBuzzTimer && "flashHeader"}`}>
                                <div className="header-toaster-timer-border">
                                    {
                                        this.props.timer !== 0 && (<h2>{this.props.timer}</h2>)
                                    }
                                </div>
                            </div>
                        ): <></>}
                    </div>
                </div>
            </div>
        );
    }
}
