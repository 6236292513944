import React, { Component, useRef, useEffect } from 'react';
import './HostGameView.scss';

export default class GameOver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            gameState: props.gameState,
        };
    }

    componentDidMount() {
        this.props.VO.playMusic('music.startGameMusic')
        this.props.VO.fadeOutMusic(this.props.VO.vo, 'music.intermissionMusic', 500)
    }

    

    componentWillMount() {
        console.log("player from GameOver: ", this.props.players)

        const { players, teamAScore, teamBScore } = this.props;
        console.log("players: ", players)
        const teamAPlayers = this.props.players.filter(player => player.ucData.team === 'a');
        const teamBPlayers = this.props.players.filter(player => player.ucData.team === 'b');

        this.setState({
            teamAPlayers: teamAPlayers,
            teamBPlayers: teamBPlayers,
        });

        console.log("team scores from gameOver: ", 'team a: ', this.props.teamAScore, 'team b: ', this.props.teamBScore);

        this.setState({
            teamsDraw: teamAScore === teamBScore,
            winningTeam: teamAScore > teamBScore ? 'Team A' : 'Team B',
            winningPlayers: teamAScore > teamBScore ? teamAPlayers : teamBPlayers,
            winningScore: teamAScore > teamBScore ? teamAScore : teamBScore,
        });

        //play winners vo
        if (teamAScore > teamBScore) {
            this.props.audio.voTeamAWins.loaded?.play();
        } else if (teamAScore < teamBScore) {
            this.props.audio.voTeamBWins.loaded?.play();
        } else {
            this.props.audio.voItsATie.loaded?.play();
        }
    }

    onNewGame = () => {
        this.state.room.send("restart_game");
        this.props.audio.voTeamAWins.loaded?.stop();
        this.props.audio.voTeamBWins.loaded?.stop();
        this.props.audio.voItsATie.loaded?.stop();
    }

    render() {
        return (
            <div className="game-over-wrapper one-team-container">
                <div className="game-over-container">
                    {
                        !this.state.teamsDraw ?
                            <>
                                <h1>{this.state.winningTeam} Wins!</h1>
                                <div class="game-over-players-wrapper" >
                                    <h2>{this.state.winningScore} {this.state.winningScore === 1 ? 'point' : 'points'} </h2>
                                    <div className="game-over-players-container">
                                        {
                                            this.state.winningPlayers.map(player => (
                                                <div className="player-icon-container" >
                                                    <div className={`player-icon`}>
                                                        {
                                                            player.ucData.playerAvatar.length > 2 ?
                                                                <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />
                                                                :
                                                                <div className="header-profile-image" >
                                                                    <div class="circle-loading2" />
                                                                </div>
                                                        }
                                                        <div className="player-icon-name-container">
                                                            <h3 key={player.id}>{player.name}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <h1>Teams are Tied!</h1>
                                <div class="game-over-players-draw">
                                    <div class="game-over-players-wrapper">
                                        <h2>{this.props.teamAScore}  {this.props.teamBScore === 1 ? 'point' : 'points'}</h2>
                                        <div className="game-over-players-container">
                                            {
                                                this.state.teamAPlayers.map(player => (
                                                    <div className="player-icon-container" >
                                                        <div className={`player-icon`}>
                                                            {
                                                                player.ucData.playerAvatar.length > 2 ?
                                                                    <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />
                                                                    :
                                                                    <div className="header-profile-image" >
                                                                        <div class="circle-loading2" />
                                                                    </div>
                                                            }
                                                            <div className="player-icon-name-container">
                                                                <h3 key={player.id}>{player.name}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div class="game-over-players-wrapper">
                                        <h2>{this.props.teamBScore} Points</h2>
                                        <div className="game-over-players-container">
                                            {
                                                this.state.teamBPlayers.map(player => (
                                                    <div className="player-icon-container" >
                                                        <div className={`player-icon`}>
                                                            {
                                                                player.ucData.playerAvatar.length > 2 ?
                                                                    <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />
                                                                    :
                                                                    <div className="header-profile-image" >
                                                                        <div class="circle-loading2" />
                                                                    </div>
                                                            }
                                                            <div className="player-icon-name-container">
                                                                <h3 key={player.id}>{player.name}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                <div className="start-new-game-container">
                    <button className="game-over-button" onClick={this.onNewGame}>NEW GAME</button>
                </div>
            </div>
        );
    }
}
