import React, { useState, useEffect } from 'react'
import './WebsiteLanding.scss'
import QRLogin from '../QRLogin/QRLogin';
import logo from './images/PlaceHolderLogo.png'

import icon1 from './images/Merge1.png'
import icon2 from './images/Merge2.png'
import icon3 from './images/Merge3.png'
import icon4 from './images/RichMerge.png'
import icon5 from './images/Merge4.png'

import roundBackground from './images/RoundWidgetBackgroudn.png'
import jobbie from './images/jobbie.png'
import walle from './images/walle.png'
import presley from './images/presley.png'
import WitwMap from './images/WitwMap.png'

import Big from './images/Big.png'
import Small from './images/Small.png'

import camera from './images/camera-svgrepo-com.svg'
import scan from './images/scan-svgrepo-com.svg'
import tv from './images/tv-svgrepo-com.svg'
import star from './images/star.svg'
import QRCode from './images/QRcode.png'
import splashBackground from './images/splashBackground.png'
const icons = [
    icon1, icon2, icon5, icon3, icon4
]

export default function WebsiteLanding(props) {
    const [scale, setScale] = useState(1);

    //useEffect(() => {
    //    document.title = "Universally Challenged - The Ultimate Christmas Party Quiz!";

    //    const faviconLink = document.querySelector("link[rel='icon']");
    //    if (faviconLink) {
    //        faviconLink.href = './images/PlaceHolderLogo.png';
    //    } else {
    //        const newFavicon = document.createElement("link");
    //        newFavicon.rel = "icon";
    //        newFavicon.href = './images/PlaceHolderLogo.png';
    //        document.head.appendChild(newFavicon);
    //    }
    //}, []);

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const threshold = 0.2 * (scrollHeight - clientHeight);
        const factor = 0.2;
        let newScale = 1;
        if (scrollTop > threshold) {
            const adjustedScroll = scrollTop - threshold;
            newScale = Math.max(1 - adjustedScroll / 1000, 0.5);
        }
        const landingPageContainer = document.querySelector('.landing-page-container');
        if (landingPageContainer) {
            // Move the background down as the user scrolls
            landingPageContainer.style.backgroundPosition = `center -${scrollTop * factor}px`;
        }
        setScale(newScale);
    };

    const onQRLogin = () => {
        console.log("SHUSHS")
    }

    const handleLogin = () => {
        props.handleLogin()
    }

    const handlePurchase = () => {
        props.handleRegister()
    }

    console.log("ownes", props.ownsGame)
    return (

        <div className="landing-page-container" onScroll={handleScroll}>
            <div className="vignette-overlay" />
            <div className="nav-bar-container">
                <img className="logo" src={logo} alt="logo" />
                <div className="buttons-holder">
                    <button onClick={() => handleLogin()}>
                        Login
                    </button>
                    <button onClick={() => handlePurchase()}>
                        Register
                    </button>
                </div>
            </div>
            <div className="landing-page-splash-container">

                <div className="landing-page-splash">
                    <img src={splashBackground} className="splash-background" alt="background" />
                    <div className="landing-page-splash-contents">
                        <div className="landing-page-splash-background" style={{ transform: `scale(${scale})` }} />
                        <div className="landing-page-mobile-header">
                            Looking to log in? Head over to your TV, Laptop or Tablet and visit UCTV.ai to play.
                        </div>
                        {props.isLoggedIn ?
                            <>
                                <div className="landing-page-QR-container">
                                    <button onClick={props.onLogOut}>
                                        LOGOUT
                                    </button>
                                </div>
                                <button className="logout-mobile" onClick={props.onLogOut}>
                                    LOGOUT
                                </button>
                            </>
                            :
                            <div className="landing-page-QR-container">
                                <QRLogin onLogin={() => onQRLogin()} text="SCAN ME" ></QRLogin>
                                {/*<img src={QRCode} alt="QRCode" className="QR-Code" />*/}
                                {/*<h1>*/}
                                {/*    SCAN ME TO LOGIN!*/}
                                {/*</h1>*/}
                                <h2>
                                    Own it? Log in.
                                </h2>
                                <button onClick={() => handleLogin()}>
                                    LOGIN
                                </button>
                            </div>
                        }


                        <div className="landing-page-splash-content">
                            <div className="container">
                                <RenderTextToDiv text="THE ULTIMATE CHRISTMAS PARTY QUIZ" additionalStyles="HEADER" />
                                {/*<div className="landing-page-splash-icons">*/}
                                {/*    {*/}
                                {/*        icons.map((icon, index) => (*/}
                                {/*            <div className="icon-container" key={index}>*/}
                                {/*                <img src={icon} alt={`icon-${index}`} className="icon" />*/}
                                {/*                <div className="dashed-line" />*/}
                                {/*            </div>*/}
                                {/*        ))*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <RenderTextToDiv text={<>Grab your friends & family. <br />Huddle around the TV. <br />Play the best party game available this Christmas!</>} additionalStyles="HEADER-2" />
                            </div>

                            <div className="container-2">
                                {props.ownsGame && props.isLoggedIn ?
                                    <>
                                        <button className="landing-purchase-button host-button" onClick={props.onHostGame}>
                                            <h1>Start Game</h1>
                                        </button>
                                        <button className="landing-purchase-button join-button" onClick={() => window.location.href = "/client"}>
                                            <h1>Join Game</h1>
                                        </button>
                                    </>
                                    :
                                    <button className="landing-purchase-button" onClick={() => handlePurchase()}>
                                        <h1>PLAY FOR FREE!</h1>
                                        {/*<h1>BUY NOW!</h1>*/}
                                        {/*<h2>£9.99</h2>*/}
                                    </button>
                                }
                                {!props.ownsGame && <RenderTextToDiv text="Instant access. 30 days of play." additionalStyles="HEADER-2" />}
                                {/*<RenderTextToDiv text="TO START PLAYING, PLEASE HEAD OVER TO UCTV.AI ON YOUR TV/LAPTOP." additionalStyles="HEADER-2 SHOW-MOBILE" />*/}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/*<div className="stars-background">*/}
            {/*    <img src={Small} alt="Big" className="big" /> */}
            {/*    <img src={Small} alt="Small" className="small" /> */}
            {/*</div>*/}



            <div className="landing-page-rounds">
                <div className="landing-page-rounds-container">
                    <RenderTextToDiv text="JOIN THE FUN!" additionalStyles="HEADER" />
                    <RenderTextToDiv text="SIX ROUNDS OF PARTY GAMES" additionalStyles="HEADER-2" />
                    <div className="landing-page-content-games-round-container" >
                        <RoundWidget header="Connect Four" footer={1} round={1} footerText="What's the connection?" />
                        <RoundWidget header="Where In The World" footer={1} footerText="London, England" round={2} />
                        <RoundWidget header="Lyric Linguist" footer={1} round={3} footerText="Name the song" />
                        <RoundWidget header="Missing Vowels" footer={1} footerText="Geography" round={4} />
                        <RoundWidget header="Movies By Monet" footer={1} footerText="What's the movie?" round={5} />
                        <RoundWidget header="Face Off" footer={1} round={6} footerText="Who's this?" />
                    </div>
                </div>
                <div className="how-to-play-wrapper">
                    <RenderTextToDiv text="HOW TO PLAY" additionalStyles="HEADER" />
                    <div className="how-to-play-container">
                        <div className="how-to-play-widget-container">
                            <div className="how-to-play-widget">
                                <div className="how-to-play-widget-index">
                                    1
                                </div>
                                <img src={tv} alt="camera" className="svg" />
                                <h2>ON YOUR TV, HEAD ON OVER TO <span>UCTV.AI</span> AND CREATE A NEW ROOM.</h2>

                            </div>
                        </div>
                        <div className="how-to-play-widget-container">
                            <div className="how-to-play-widget">
                                <div className="how-to-play-widget-index">
                                    2
                                </div>
                                <img src={scan} alt="camera" className="svg" />
                                <h2> ON YOUR PHONE, <span>SCAN</span> THE QR CODE THAT YOUR TV DISPLAYS TO JOIN THE ROOM.</h2>

                            </div>
                        </div>
                        <div className="how-to-play-widget-container">

                            <div className="how-to-play-widget">
                                <div className="how-to-play-widget-index">
                                    3
                                </div>
                                <img src={camera} alt="camera" className="svg" />
                                <h2> ONCE YOU HAVE JOINED THE ROOM, <span>UPLOAD OR SELECT</span> YOUR CHARACTER.</h2>

                            </div>
                        </div>

                    </div>
                </div>
                <ReviewsContainer />

                <div className="footer-container">

                    <div>
                        <button onClick={() => window.location.href = "/help"} >Help</button>
                        <button onClick={() => window.location.href = "/terms-and-conditions"} >Terms & Conditions</button>
                        <button onClick={() => window.location.href = "/cookie-policy"} >Cookie Policy</button>
                        <button onClick={() => window.location.href = "/data-protection"} >Data Protection</button>
                        <button onClick={() => window.location.href = "/privacy-policy"} >Privacy Policy</button>
                    </div>
                    <img src={logo} alt="logo" />


                </div>
            </div>
         
        </div>
    )
}

const ReviewsContainer = (props) => {
    const reviews = [
        {
            name: "Louise D.",
            review: "All the family (8-75) enjoy the game city games! We all have a different favourite...",
            stars: 4,
        }, {
            name: "India M.",
            review: "plsssss release more games to add on :) we love it",
            stars: 5,
        }, {
            name: "Paul M.",
            review: "Really enjoyed playing games with the family. Evreyone in their comfy chairs as opposed to sitting at the table when playing traditional games. Good fun!",
            stars: 5,
        }, {
            name: "Kathryn C.",
            review: "We love our games nights. These games are entertaining enough tot keep everyone engaged.",
            stars: 5,
        }, {
            name: "Christina M.",
            review: "Cracking family game fun and utter nonsense. Easy for all ages and abilities. Highly recommend! ",
            stars: 5,
        }, {
            name: "Marybeth D.",
            review: "Great games all the family can enjoy. Lots of fun and laughter had by all.",
            stars: 5,
        },
        {
            name: "Tessa G.",
            review: "7 of us haven't laughed as much in years. it was so much fun. i used my ipad and mirrored it to the tV then we all used our phones to draw.",
            stars: 5,
        }
    ]
    return (
        <div className="reviews-carousel-container">
            <div className="reviews-container">
                <div className="group">
                    {
                        reviews.map((review, index) => (
                            <ReviewWidget key={index} name={review.name} review={review.review} stars={review.stars} />
                        ))
                    }

                </div>
                <div aria-hidden className="group">
                    {
                        reviews.map((review, index) => (
                            <ReviewWidget key={index} name={review.name} review={review.review} stars={review.stars} />
                        ))
                    }

                </div> <div aria-hidden className="group">
                    {
                        reviews.map((review, index) => (
                            <ReviewWidget key={index} name={review.name} review={review.review} stars={review.stars} />
                        ))
                    }

                </div>
            </div>


        </div>
    )
}
const ReviewWidget = (props) => {

    return (
        <div className="review-widget-wrapper">
            <div className="review-widget-container">
                <div>
                    <h1>{props.name}</h1>
                    <div>
                        {Array.from({ length: props.stars }).map((_, index) => (
                            <img key={index} src={star} alt="star" />
                        ))}
                    </div>
                </div>

                <h2>{props.review}</h2>
            </div>
        </div>

    );
};

const RoundWidget = (props) => {

    return (
        <div className="round-widget-container">
            <div className="round-widget">
                <div className="round-widget-header-container">
                    <div className="round-widget-header">{props.header}</div>
                </div>
                <div className="round-widget-content">
                    {renderWidgetContents(props.round)}
                </div>
                <div className="round-widget-footer-container">
                    <div className={`round-widget-footer ${props.footer === 0 ? 'word-box' : 'footer'}`}>
                        {
                            props.footer === 0 ? (
                                <>
                                    <div className="word-letter" />
                                    <div className="word-letter" />
                                    <div className="word-letter" />
                                    <div className="word-letter" />
                                    <div className="word-letter" />
                                </>
                            ) : (
                                <>
                                    {props.footerText}
                                </>
                            )
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

const renderWidgetContents = (round) => {

    switch (round) {
        case 1:
            return (
                <>
                    <div className="connect-four-container">
                        <div className="connect-four" style={{ "borderColor": "#22a39a" }}>
                            APPLE
                        </div>
                    </div> <div className="connect-four-container">
                        <div className="connect-four" style={{ "borderColor": "#a3a322" }}>
                            BANANA
                        </div>
                    </div> <div className="connect-four-container">
                        <div className="connect-four" style={{ "borderColor": "#a34522" }}>
                            STRAWBERRY
                        </div>
                    </div> <div className="connect-four-container">
                        <div className="connect-four" style={{ "borderColor": "#a32222" }}>
                            PINEAPPLE
                        </div>
                    </div>
                </>
            )
        case 2:
            return (
                <>
                    <img src={WitwMap} alt="WitwMap" className="witw-map" />
                </>
            )
        case 3:
            return (
                <>
                    <div className="lyric-linguist-container">
                        <img src={presley} alt="WitwMap" className="witw-map" />

                    </div>
                </>
            )
        case 4:
            return (
                <div className="missing-vowels-container">
                    <div className="missing-vowel">
                        <RenderTextToDiv text="L" additionalStyles="LL" />
                        <div className="missing-vowel-underscore" />
                    </div>
                    <div className="missing-vowel">
                        <div className="missing-vowel-underscore" />
                    </div>
                    <div className="missing-vowel">
                        <RenderTextToDiv text="N" additionalStyles="LL" />
                        <div className="missing-vowel-underscore" />
                    </div>
                    <div className="missing-vowel">
                        <RenderTextToDiv text="D" additionalStyles="LL" />
                        <div className="missing-vowel-underscore" />
                    </div>
                    <div className="missing-vowel">
                        <div className="missing-vowel-underscore" />
                    </div><div className="missing-vowel">
                        <RenderTextToDiv text="N" additionalStyles="LL" />
                        <div className="missing-vowel-underscore" />
                    </div>
                </div>
            )
        case 5:
            return (
                <>
                    <>
                        <div className="lyric-linguist-container">
                            <img src={walle} alt="WitwMap" className="witw-map" />

                        </div>
                    </>
                </>
            )
        case 6:
            return (
                <>
                    <>
                        <div className="face-smash-container">
                            <img src={jobbie} alt="WitwMap" className="witw-map" />

                        </div>
                    </>
                </>
            )

        default:
            return (
                <>

                </>
            )
    }
}

const RenderTextToDiv = ({ text, additionalStyles }) => {
    return (
        <>
            <div className={`text ${additionalStyles}`}>
                {text}
                <div className={'face'}>{text}</div>
            </div>
        </>
    )
}
