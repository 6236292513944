import React, { Component } from 'react';
import FormPage from './Utility/FormPage';
import LoginForm from './Forms/Login';
import RegisterForm from './Forms/Register';
import ForgotPassword from './Forms/ForgotPassword';
import ResetPassword from './Forms/ResetPassword';
import Home from './Home';
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import AuthService from '../services/auth';
import Checkout from './Checkout';
import { authoriseUser, logout, autoLoginQRSession } from "actions/auth";
import cross from '../images/xmark-solid.svg';


// WEBSITE LANDING:
import WebsiteLanding from './WebsiteLanding/WebsiteLanding.js'
class LandingPage extends Component {

    constructor(props) {
        super(props);

        const searchParams = new URLSearchParams(window.location.search);

        this.state = {
            isRegister: false,
            isAccountInit: false,
            isLoggedIn: this.props.auth.isLoggedIn,
            checkingGames: true,
            QRSessionID: searchParams.get("QRSessionID"),
            resetToken: searchParams.get('tokenReset'),
        };
        this.loginChecks = null;
    }

    componentDidMount() { 
        if (this.props.auth.isLoggedIn) {
            if (this.state.QRSessionID) setTimeout(() => { this.authoriseQRSession(this.state.QRSessionID) }, 200);
            else this.getGames();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.auth.isLoggedIn !== this.props.auth.isLoggedIn) {

            if (this.props.auth.isLoggedIn) {
                this.getGames();
            }
        }
    }

    onBuyGame = () => {
        console.log("Buy Game");
        if (!this.state.isLoggedIn) this.setState({ purchasingGame: true,  isRegister: true, isForgotPassword: false, isAccountInit: true })
        else this.setState({ purchasingGame: true, isRegister: false, isForgotPassword: false, isAccountInit: true })
    }

    goToRegister = () => {
        console.log("Go to Register");
        this.setState({ isRegister: true, isForgotPassword: false, isAccountInit: true })
    }

    goToLogin = () => {
        this.setState({ isRegister: false, isForgotPassword: false, isAccountInit: true, resetToken: null})
    }

    goToForgotPassword = () => {
        this.setState({ isForgotPassword: true, isRegister: false, isAccountInit: true })
    }

    getGames = () => {
        console.log("Get Games with Email : ", this.props.auth.user?.email);
        AuthService.getGames(this.props.auth.user?.email).then((res) => {
            console.log("getGames", res);
            if (res.games.length > 0) {
                this.props.authoriseUser();
            } else {
                this.setState({ checkingGames: false, });
            }
        }).catch((err) => {
            console.log("getGames error", err);
            this.setState({ checkingGames: false, });
        });
    }

    authoriseQRSession = async (QRSessionID) => {        
        const UserID = this.props.auth.user.id;
        this.setState({ loading: true });
        await this.props.autoLoginQRSession(QRSessionID, UserID);
        this.setState({ loading: true });
        this.props.navigate(`/client`);
    }

    gamePurchased = () => {
        this.getGames();
    }

    onQRLogin = () => {
        //if (this.props.auth.isAuthorised) {
        //    this.setState({ isHosting: true });
        //}
        //window.location.reload();
        //this.props.navigate("/");
        //this.setState({ checkingGames: true, }, ()=> this.getGames());
    }

    logOut = () => {
        this.props.logout();
        this.setState({ isRegister: false, isForgotPassword: false, isAccountInit: false, resetToken: null })
    }

    hostGame = () => {
        this.setState({ isHosting: true})
    }

    closeModal = () => {
        this.setState({ isAccountInit: false, resetToken: null, intendingToBuy: false})
    }

    stopHosting = () => {
        this.setState({ isHosting: false })
    }

    cancelCheckOut = () => {
        this.setState({ purchasingGame: false });
    }

    renderCurrentForm = () => {
        if (this.state.isRegister) return <RegisterForm goToLogin={this.goToLogin} goToRegister={this.goToRegister} QRSessionID={this.state.QRSessionID} />
        if (this.state.isForgotPassword) return < ForgotPassword goToLogin={this.goToLogin} QRSessionID={this.state.QRSessionID} />
        if (this.state.resetToken) return < ResetPassword resetToken={this.state.resetToken} goToLogin={this.goToLogin} />
        return < LoginForm onQRLogin={this.onQRLogin} goToLogin={this.goToLogin} goToRegister={this.goToRegister} onForgotPassword={this.goToForgotPassword} QRSessionID={this.state.QRSessionID} />
    }

    render() {
        console.log("perchaseing", this.state.purchasingGame);
        console.log("isAuthorised", this.props.auth.isAuthorised);
        console.log("isLoggedIn", this.props.auth.isLoggedIn);

        return (
            <>
                {
                    !this.state.isHosting && !this.state.QRSessionID && !this.state.resetToken &&
                    <WebsiteLanding onHostGame={this.hostGame} handleLogin={() => this.goToLogin()} isLoggedIn={this.props.auth.isLoggedIn} ownsGame={this.props.auth.isAuthorised} onLogOut={this.logOut} handleRegister={this.onBuyGame} /> 
                }

                {
                    this.state.purchasingGame && !this.props.auth.isAuthorised && this.props.auth.isLoggedIn &&
                        <FormPage>
                            {
                                this.state.checkingGames ?
                                    <div className="text-center">
                                        <h1>Checking Games</h1>
                                    </div>
                                    :
                                    <Checkout onCheckout={this.gamePurchased} onClose={this.cancelCheckOut} />
                            }
                        </FormPage >
                }
                
                {(this.state.QRSessionID || this.state.resetToken || (!this.props.auth.isLoggedIn && this.state.isAccountInit)) &&
                    <FormPage>
                        <button className="close-button" onClick={() => this.closeModal()}>
                            <img src={cross} alt="close-button" />
                        </button>
                        {
                            this.renderCurrentForm()
                        }
                    </FormPage>
                }
               
                {this.state.isHosting && this.props.auth.isAuthorised && this.props.auth.isLoggedIn && !this.state.QRSessionID && !this.state.resetToken &&
                    <Home onLogout={this.logOut} onStopHosting={this.stopHosting} />}
            </>
        );
    }
}

//{
//    is login or register
//    <FormPage>
//        {
//            this.state.isRegister ?
//                <RegisterForm goToLogin={this.goToLogin} goToRegister={this.goToRegister} QRSessionID={this.state.QRSessionID} />
//                :
//                <LoginForm onQRLogin={this.onQRLogin} goToLogin={this.goToLogin} goToRegister={this.goToRegister} QRSessionID={this.state.QRSessionID} />
//        }
//    </FormPage>
//}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        authoriseUser: () => {
            return dispatch(authoriseUser())
        },
        logout: () => {
            return dispatch(logout())
        },
        autoLoginQRSession: (QRSessionID, UserID) => {
            return dispatch(autoLoginQRSession(QRSessionID, UserID))
        }
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LandingPage));