import React, { Component, useState, useEffect } from 'react';
import './HostGameView.scss';

export default class MissingVowels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            word: props.word || '',
        };
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return (
            <div className="host-missing-vowels-container">
                <MissingVowelsLetterBox word={this.props.word} showCorrectAnswer={this.props.showCorrectAnswer} />
            </div>
        );
    }
}

const MissingVowelsLetterBox = ({ word, showCorrectAnswer }) => {
    //const [splitWord, setSplitWord] = useState([]);
    const vowels = ['a', 'e', 'i', 'o', 'u'];

    useEffect(() => {
        //const formattedAnswer = Array.isArray(word) ? word.join('') : word;
        //const slicedWord = typeof formattedAnswer === 'string' ? formattedAnswer.split('') : [];
        //setSplitWord(slicedWord);
    }, [word]);

    const renderTextDiv = (text, additionalStyles) => {
        return <div className={`bold-text-outline extra-bold`}>{text}
            <div className={'bold-text'}>{text} </div>
        </div>
    }

    const splitIntoLines = (text, maxCharsPerLine) => {
        text = Array.isArray(text)
            ? text.filter(x => x).join('')
            : text;
        if (text.length > 0) {
            const words = text.split(' ');
            const lines = [];
            let currentLine = '';

            for (let word of words) {
                const appended = currentLine + (currentLine ? ' ' : '') + word;
                if (appended.length > maxCharsPerLine) {
                    lines.push(currentLine);
                    currentLine = word;
                } else {
                    currentLine = appended;
                }
            }

            if (currentLine) {
                lines.push(currentLine);
            }

            console.log("Split lines lines lines : ", lines);
            return lines;
        }
        return [];
    }

    const maxCharsPerLine = 12;
    const lines = splitIntoLines(word, maxCharsPerLine);

    return (
        <div className="host-missing-vowels-letter-box-container">
            {
                lines.map((line, lineIndex) => {
                    return <div key={lineIndex} className="missing-vowels-line">
                        {
                            line.split('').map((letter, index) => {
                                return <div key={index} className={`missing-vowels-letter ${letter === ' ' ? 'missing-vowels-space' : ''}`}>
                                    <h2>
                                        {
                                            showCorrectAnswer ?
                                                renderTextDiv(letter)
                                                :
                                                !vowels.includes(letter.toLowerCase()) && renderTextDiv(letter)
                                        }
                                    </h2>
                                    <div className="underline-placeholder" />
                                </div>
                            })
                        }
                    </div>
                })
            }
        </div>
    );
};
