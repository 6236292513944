import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import styles from '../Utility/FormStyles.module.scss';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { connect, } from 'react-redux';
import { withRouter } from 'components/withRouter';
import { login, register } from 'actions/auth';

class RegisterForm extends Component {
    static displayName = RegisterForm.name;

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            confirmEmail: "",
            password: "",
            loading: false,
            errorMessage: "",
            validated: false,
            success: false,

        };
    }

    componentWillMount() {
        //if (this.props.auth.isLoggedIn) {
        //    return setTimeout(() => this.props.navigate("/"));
        //}
    }

    componentDidUpdate(nextProps, nextState) {
        //if (this.props.auth.isLoggedIn) {
        //    return setTimeout(() => this.props.navigate("/"));
        //}
    }


    onChangeEmail = (e) => {
        const email = e.target.value;

        this.setState({ email });
    };

    onChangeConfirmEmail = (e) => {
        const confirmEmail = e.target.value;

        this.setState({ confirmEmail });
    }

    onChangePassword = (e) => {
        const password = e.target.value;
        const validLength = password.length >= 8;
        const hasNumber = (/\d/g).test(password);
        const upperCase = (/[A-Z]/g).test(password);
        const lowerCase = (/[a-z]/g).test(password);
        this.setState({ password, validLength, hasNumber, upperCase, lowerCase });
    };

    handleRegister = (e) => {
        e.preventDefault();

        if (!this.state.loading) {
            this.setState({ loading: true, errorMessage: "", });
            if (this.validate()) {
                const email = this.state.email;
                const password = this.state.password;
                this.props.register(email, password)
                    .then((res) => {
                        console.log(res);
                        this.setState({ success: true });
                        //this.props.login(email, password, this.props.QRSessionID)
                        //    .then((res) => {
                        //        console.log(res);
                        //        if (this.props.modal) {
                        //            this.props.closeModal(true);
                        //        } else {
                        //            if (this.props.QRSessionID) this.props.navigate("/client");
                        //            else this.props.navigate("/");
                        //        }
                        //    })
                        //    .catch((err) => {
                        //        this.setState({ loading: false });
                        //    });
                    })
                    .catch((err) => {
                        console.log("register err : ", err);
                        this.setState({ loading: false, errorMessage: err, });
                    });
            } else {
                this.setState({ loading: false, });
            }
        }
    };

    validate = () => {
        this.setState({ validated: true });
        // first check emails match
        if (this.state.email !== this.state.confirmEmail) {
            this.setState({ errorMessage: "Emails do not match", });
            return false;
        }

        if (!this.state.validLength || !this.state.hasNumber || !this.state.upperCase || !this.state.lowerCase) {
            this.setState({ errorMessage: "This password doesn't meet the minimum requirements", });
            return false;
        }

        return true;
    }

    doNavigation = (page) => {
        this.props.navigate(page);
    }

    render() {
        return (

            <Form onSubmit={this.handleRegister}>

                <h2 className={styles.formTitle}>Register</h2>
                {
                    this.state.success ?
                        <Alert variant={"success"}>
                            Please confirm your email using the link sent to your inbox.
                        </Alert>
                        :
                        <>
                            {/*<h3 className={styles.formDescription}>Please register before  so we can add the game to your account.</h3>*/}

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                {/*<Form.Label>Email address</Form.Label>*/}
                                <Form.Control type="email" placeholder="Enter email" required onChange={this.onChangeEmail} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formConfirmEmail">
                                {/*<Form.Label>Confirm Email address</Form.Label>*/}
                                <Form.Control type="email" placeholder="Confirm email" required onChange={this.onChangeConfirmEmail} isInvalid={this.state.email !== this.state.confirmEmail} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                {/*<Form.Label>Password</Form.Label>*/}
                                <Form.Control className="mb-2" type="password" placeholder="Password" required onChange={this.onChangePassword} isInvalid={this.state.password?.length > 0 && (!this.state.validLength || !this.state.hasNumber || !this.state.upperCase || !this.state.lowerCase)}></Form.Control>
                                <p>Your password must have <span className={this.state.validLength ? "text-success" : "text-danger"}>8+ characters</span >, <span className={this.state.hasNumber ? "text-success" : "text-danger"}>number</span>, <span className={this.state.upperCase ? "text-success" : "text-danger"}>upper</span> and <span className={this.state.lowerCase ? "text-success" : "text-danger"}>lower</span> case letters</p>

                            </Form.Group>
                            {
                                this.state.errorMessage.length > 0 &&
                                <Alert variant={"danger"}>
                                    {this.state.errorMessage}
                                </Alert>
                            }

                            <div className={styles.buttonsWrapper}>
                                <button className={styles.button} type="submit">{this.state.loading ? <Spinner animation="border" /> : "Register"}</button>
                            </div>
                            <p style={{ marginBottom: "5px" }} > By registering you are agreeing to the <span className={styles.passwordForget} onClick={() => window.open("/terms-and-conditions")}>Universally Challenged Terms</span></p>
                            <Link className={styles.passwordForget} to="" onClick={() => this.props.goToLogin()}>Already have an account?</Link>

                        </>
                }

            </Form>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        register: (email, password) => {
            return dispatch(register(email, password))
        },
        login: (email, password, QRSessionID) => {
            return dispatch(login(email, password, QRSessionID))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterForm));
