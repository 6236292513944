import React, { Component, useState, useEffect } from 'react';
import './HostGameView.scss';

export default class ConnectFour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            ConnectFourData: props.ConnectFourData,
            answer: props.correctAnswer,  
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
        this.updateWindowDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        clearInterval(this.hintInterval);
        clearInterval(this.capsuleInterval);
    }


    componentDidUpdate(prevProps) {
        if (prevProps.ConnectFourData !== this.props.ConnectFourData) {
            this.setState({
                ConnectFourData: this.props.ConnectFourData.filter(x => x != null),
            });
        }
        if (prevProps.timer !== this.props.timer) {
            if (this.props.timer === 24) {
                this.props.audio.sfxConnectFourSwoosh.loaded?.play()
            } else if (this.props.timer === 18) {
                this.props.audio.sfxConnectFourSwoosh.loaded?.play()

            } else if (this.props.timer === 10) {
                this.props.audio.sfxConnectFourSwoosh.loaded?.play()

            } 
        }
    }

    updateWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({ windowHeight: height, windowWidth: width });
    }

    render() {
        const hintOffset = this.props.hintsRevealed * (46 / 4);

        var pts = "";

        if (this.props.timer > 24) {
            pts = "10";
        } else if (this.props.timer > 18) {
            pts = "6";
        } else if (this.props.timer > 10) {
            pts = "3";
        } else {
            pts = "1";
        }

        return (
            <div className="host-connect-four-container">
                <div className="host-connect-four-hints-wrapper">
                    <div style={this.props.timer > 0 ? {} : { display: 'none' }}>
                        <div className={`connect-four-points-container ${this.props.isBuzzTimer && "flash"}`} style={{ transform: `translateY(${hintOffset}vh)` }}>
                        
                            {Array.from({ length: 28 }, (_, i) => (
                                <div key={i} className="connect-four-sec" style={{ transform: `rotate(${-12.85 * i}deg)` }}>
                                    <div
                                        className={`connect-four-capsule capsule${i}`}
                                        style={{
                                            backgroundColor: i <= this.props.timer ? '#ff7e55' : '#ffd3bf',
                                            opacity: i <= this.props.timer ? 1 : 0.5,
                                        }}
                                    />
                                </div>
                            ))}
                            <h1>{pts}</h1>
                        
                        </div>
                    </div>
                    {this.props.ConnectFourData?.filter(x => x).slice(0, this.props.hintsRevealed + 1).map((hint, index) => (
                        <div className="host-connect-four-hint-wrapper slide-up" key={index}>
                        <div className="blur-hint"/>
                            <div className="host-connect-four-hint-container">
                                <h2 className="bold-header">{hint}</h2>
                            </div>
                        </div>
                    ))}
                </div>
                <ConnectFourLetterBox answer={this.props.correctAnswer} showCorrectAnswer={this.props.showCorrectAnswer } />
            </div>
        );
    }
}

const ConnectFourLetterBox = ({ answer, showCorrectAnswer }) => {
    const [splitAnswer, setSplitAnswer] = useState([]);

    useEffect(() => {
        const formattedAnswer = Array.isArray(answer) ? answer.join('') : answer;
        const slicedWord = typeof formattedAnswer === 'string' ? formattedAnswer.split('') : [];
        setSplitAnswer(slicedWord);
    }, [answer]);

    return (
        <div className={`connect-four-letter-box-container ${showCorrectAnswer ? 'show-correct-answer-bounce' : 'CFLB-start'}`}>
            {splitAnswer.map((letter, index) => (
                <div key={index} className={`connect-four-letter ${letter === ' ' ? 'connect-four-space' : ''}`}>
                    {
                        showCorrectAnswer && (
                            <h1>{letter}</h1>
                        )
                    }
                </div>
            ))}
        </div>
    );
};
