import React, { Component, useEffect } from 'react';
import './HostGameView.scss';

export default class TeamWidgetContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,

            teamAPointComponents: [],
            teamADisplayScore: 0,

            teamBPointComponents: [],
            teamBDisplayScore: 0,
        };
    }

    componentDidMount() {
        this.props.room.state.ucData.listen("teamAScore", (value, prevValue) => {
            if (value > 0) {
                let teamAPointComponents = [...this.state.teamAPointComponents];
                teamAPointComponents.push(<div key={`teamA-scoreAdd-${value}`} className={"pointAdd"}>+{value - prevValue}</div>);
                this.props.audio.sfxLevelUp.loaded?.play()
                this.setState({ teamAPointComponents });
                setTimeout(() => {
                    teamAPointComponents = [...this.state.teamAPointComponents];
                    teamAPointComponents.shift();
                    this.setState({ teamAPointComponents });
                }, 2000);
            }

            let teamADisplayScore = this.state.teamADisplayScore;
            let interval = setInterval(() => {
                if (teamADisplayScore < value) {
                    teamADisplayScore++;
                    this.setState({ teamADisplayScore });
                } else if (teamADisplayScore > value) {
                    teamADisplayScore--;
                    this.setState({ teamADisplayScore });
                } else {
                    clearInterval(interval);
                }
            }, 50);
        });

        this.props.room.state.ucData.listen("teamBScore", (value, prevValue) => {
            if (value > 0) {
                let teamBPointComponents = [...this.state.teamBPointComponents];
                teamBPointComponents.push(<div key={`teamB-scoreAdd-${value}`} className={"pointAdd"}>+{value - prevValue}</div>);
                this.setState({ teamBPointComponents });
                setTimeout(() => {
                    teamBPointComponents = [...this.state.teamBPointComponents];
                    teamBPointComponents.shift();
                    this.setState({ teamBPointComponents });
                }, 2000);
            }

            let teamBDisplayScore = this.state.teamBDisplayScore;
            let interval = setInterval(() => {
                if (teamBDisplayScore < value) {
                    teamBDisplayScore++;
                    this.setState({ teamBDisplayScore });
                } else if (teamBDisplayScore > value) {
                    teamBDisplayScore--;
                    this.setState({ teamBDisplayScore });
                } else {
                    clearInterval(interval);
                }
            }, 50);
        });
    }

    getPlayerHighlight(player, name = false) {
        if (this.props.round === "where_in_the_world") {
            if (name === false) {
                return null;
            }
        }

        if (player.ucData.hasSubmittedAnswer) {
            if (this.props.roundOver && this.props.answerType === 2) {
                if (player.ucData.answerCorrect) {
                    return "highlightCorrect";
                } else {
                    return "highlightIncorrect";
                }
            } else if (this.props.answerType === 1) {
                if (player.ucData.team === "a") {
                    if (this.props.teamACorrect) return "highlightCorrect";
                    else return "highlightIncorrect";
                } else if (player.ucData.team === "b") {
                    if (this.props.teamBCorrect) return "highlightCorrect";
                    else return "highlightIncorrect";
                }
            } else {
                return "highlight";
            }
        }  

        if (this.props.buzzedInPlayerId === player.id) {
            return "highlight";
        }
    }

    chunkArray(array, size) {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    }

    render() {
        const teamAPlayers = this.props.players.filter(player => player.ucData.team === 'a');
        const teamBPlayers = this.props.players.filter(player => player.ucData.team === 'b');

        const teamAScore = this.state.teamADisplayScore
        const teamBScore = this.state.teamBDisplayScore;
        const unplacedPlayers = this.props.players.filter(player => !player.ucData.team);

        const count = unplacedPlayers.length;    
        const rows = this.chunkArray(unplacedPlayers, 4);

        let teamAHighlight = "#fff", teamBHighlight = "#fff";
        if (this.props.buzzedInPlayerId.length > 0) {

            const buzzedPlayer = this.props.players.find(x => x.id === this.props.buzzedInPlayerId);
            if (buzzedPlayer) {
                if (buzzedPlayer.ucData.team === "a") {
                    teamAHighlight = "#70B8FF";
                } else if (buzzedPlayer.ucData.team === "b") {
                    teamBHighlight = "#70B8FF";
                }
            }
        }

        if (this.props.teamALocked) {
            if (this.props.teamACorrect) {
                teamAHighlight = "#70FF75";
            } else {
                teamAHighlight = "#FF4141";
            }
        }
        if (this.props.teamBLocked) {
            if (this.props.teamBCorrect) {
                teamBHighlight = "#70FF75";
            } else {
                teamBHighlight = "#FF4141";
            }
        }

        return (
            <div className="host-selecting-team-container">
                <div className="host-selecting-team-teams-container">
                    <div className="host-selecting-team-team-container">
                        <div className="host-team-info-container" >
                            <h2 className="team-header-style">TEAM A</h2>
                            <div className="host-team-points-container" style={{ border: `0.5vh solid ${teamAHighlight}` }}>
                                <h1>{teamAScore}</h1>
                                {
                                    this.state.teamAPointComponents
                                }
                            </div>
                        </div>
                        <div className={`host-selecting-team-player-wrapper`} style={{ border: `0.5vh solid ${teamAHighlight}` }}>
                           
                            <div className={`host-selecting-team-player-container player-container-grid${teamAPlayers.length}`}>
                                <div className="blur-selecting-teams-wrapper">
                                    <div className="blur-selecting-teams" />

                                </div>
                                {teamAPlayers.map(player => (
                                    <div className="player-icon-container" >
                                        <div className={`player-icon ${this.getPlayerHighlight(player)}`} style={
                                            this.props.round === "where_in_the_world"
                                                ? { backgroundColor: player.ucData.whereInTheWorldColor }
                                                : {}
                                        }>
                                            {
                                                player.ucData.playerAvatar.length > 2 ?
                                                    <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />
                                                    :
                                                    <div className="header-profile-image" >
                                                        <div class="circle-loading2" />
                                                    </div>
                                            }
                                            <div className={`player-icon-name-container ${this.getPlayerHighlight(player, true)}`}>
                                                <h3 key={player.id}>{player.name}</h3>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                   
                    <div className="host-selecting-team-team-container">
                        <div className="host-team-info-container">
                            <h2 className="team-header-style">TEAM B</h2>
                            <div className="host-team-points-container" style={{ border: `0.5vh solid ${teamBHighlight}` }}>
                                <h1>{teamBScore}</h1>
                                {
                                    this.state.teamBPointComponents
                                }
                            </div>
                        </div>
                        <div className={`host-selecting-team-player-wrapper`} style={{ border: `0.5vh solid ${teamBHighlight}` }}>
                            <div className={`host-selecting-team-player-container player-container-grid${teamBPlayers.length}`} >
                                <div className="blur-selecting-teams-wrapper">
                                    <div className="blur-selecting-teams" />

                                </div>
                                {teamBPlayers.map(player => (
                                    <div className="player-icon-container">
                                        <div className={`player-icon ${this.getPlayerHighlight(player)}`} style={
                                            this.props.round === "where_in_the_world"
                                                ? { backgroundColor: player.ucData.whereInTheWorldColor }
                                                : {}
                                        }>
                                            {
                                                player.ucData.playerAvatar.length > 2 ?
                                                    <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />
                                                    :
                                                    <div className="header-profile-image" >
                                                        <div className="circle-loading2"> </div>
                                                    </div>

                                            }
                                            <div className={`player-icon-name-container ${this.getPlayerHighlight(player, true)}`}>
                                                <h3 key={player.id}>{player.name}</h3>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

