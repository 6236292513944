import React from 'react'
import { Component } from 'react';
import http from "helpers/http.js";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../Utility/FormStyles.module.scss';
import "shared/forms.scss";
import FormPage from '../Utility/FormPage';
import AuthService from "services/auth";
import { withRouter } from 'components/withRouter';

class ConfirmEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirming: false,
            tokenInvalid: false,
            error: null,
            success: null,
            isSilk: false,
            isMounted: false
        }
    }

    componentDidMount = () => {
        console.log("Confirm email component mount");
        this.setState({
            isMounted: true
        });

        var url = process.env.REACT_APP_API_URL;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const email = urlParams.get('email');
        const tokenConfirm = urlParams.get('tokenConfirm');

        var emailString = "";
        var tokenConfirmString = "";
        if (email || tokenConfirm) {
            emailString = email.toString();
            tokenConfirmString = tokenConfirm.toString();
        }

        AuthService.confirmEmail(emailString, tokenConfirmString).then((res) => {
            console.log("Confirm email res : ", res);
            if (res.status === 200) {
                const successMessage = res.data.message;
                this.setState({
                    confirming: true,
                    success: successMessage
                });
            } else {
                this.setState({
                    confirming: false,
                    error: "Something went wrong."
                });
            }
        }).catch((err) => {
            console.log("confirm email err : ", err);
            if (this.state.isMounted) {
                if (err) {
                    const errors = err.response.data.message;
                    if (err.response.status === 401) {
                        this.setState({
                            confirming: false,
                            tokenInvalid: true,
                            error: errors
                        });
                        console.log(err);
                    } else {
                        this.setState({
                            confirming: false,
                            error: errors
                        });
                        console.log(err);
                    }
                }
            }
        });

    }

    componentWillUnmount() {
        this.setState({
            isMounted: false
        });
    }

    setSilk() {
        //this works to detect silk browser
        if (/\bSilk\b/.test(navigator.userAgent)) {
            this.setState({
                isSilk: true
            });
        }
    }

    goToHome = (e) => {
        e.preventDefault();
        this.props.navigate("/")
    }

    render() {
        return (
            <FormPage bg={true} >
                <h2 className={styles.formTitle}>Confirm Email</h2>
                <div className={styles.wrapper}>
                    <Form className={styles.form}>
                        {
                            this.state.tokenInvalid ?
                                <Form.Group className="w-100 mb-3" >
                                    <p>{this.state.error}</p>
                                </Form.Group>
                                :
                                <div className="row justify-content-center w-100 h-100 m-0">
                                    {
                                        this.state.confirming ?
                                            <>
                                                <Form.Group className="w-100 mb-3" >
                                                    <p>{this.state.success}</p>
                                                </Form.Group>
                                                <Form.Group className="w-100 mb-3" >
                                                    <div className={`${styles.buttonsWrapper} mt-3`}>
                                                        <button onClick={this.goToHome} className={`${styles.button}`} type="submit">Login into account</button>
                                                    </div>
                                                </Form.Group>
                                            </>
                                            :
                                            <>
                                                {
                                                    this.state.error ?
                                                    <Alert className="w-100 mb-3" variant={"danger"}>
                                                        {this.state.error}
                                                        </Alert>
                                                        :
                                                        <div className={`${styles.buttonsWrapper} mt-3`}>
                                                            <Spinner animation="border" />
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                        }
                    </Form>
                </div>
            </FormPage>
        )
    }
};

export default withRouter(ConfirmEmail);