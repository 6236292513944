import React, { Component } from 'react';
import styles from './FormStyles.module.scss'
import 'components/WebsiteLanding/WebsiteLanding.scss'

class FormPage extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className={`${!this.props.modal && styles.backgroundWrap} ${this.props.bg && "landing-page-bg"}`} >
                {this.props.bg && <div className="vignette-overlay" /> }
                <div className={`${styles.formWrap} text-center`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default FormPage;
