import React from 'react'
import { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from '../withRouter';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../Utility/FormStyles.module.scss';
import AuthService from 'services/auth';


class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            token: '',
            password: '',
            confirmPassword: '',
            enterEmail: false,
            input: {},
            errors: {},
            type: 'password',
            loading: false,
            message: '',
            successful: false,
        }

        this.validate = this.validate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.showHide = this.showHide.bind(this);

        this.target = React.createRef();
    }

    componentDidMount = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const email = urlParams.get('email');
        const token = urlParams.get('tokenReset');

        var emailString = "";
        var tokenString = "";
        if (email || token) {
            emailString = email.toString();
            tokenString = token.toString();

            this.setState({
                email: emailString,
                token: tokenString
            });
        }

    }


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let input = this.state.input;
        input[event.target.name] = event.target.value;

        const password = input["password"];
        const validLength = password.length >= 8;
        const hasNumber = (/\d/g).test(password);
        const upperCase = (/[A-Z]/g).test(password);
        const lowerCase = (/[a-z]/g).test(password);

        this.setState({
            [name]: value, input, validLength, hasNumber, upperCase, lowerCase
        });

        this.validate();
    }

    validate() {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter a new password.";
        }

        if (!input["confirmPassword"]) {
            isValid = false;
            errors["confirmPassword"] = "Please ensure your new password and confirmation password match.";
        }

        if (typeof input["password"] !== "undefined" && typeof input["confirmPassword"] !== "undefined") {

            if (input["password"] != input["confirmPassword"]) {
                isValid = false;
                errors["password"] = "Passwords don't match.";
            }
            else {
                if (input["password"].length < 7 &&
                    input["password"].toLowerCase() !== input["password"] &&
                    input["password"].toUpperCase() !== input["password"] &&
                    /\d/.test(input["password"]) &&
                    /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(input["password"])) {
                    isValid = false;
                    errors["password"] = "Password reset invalid, please make sure all validations are met.";
                }
                else {
                    if (input["password"].length < 7) {
                        isValid = false;
                        errors["password"] = "Password length not met.";
                    }
                    else {
                        isValid = true;
                        errors["password"] = "";
                    }
                }
            }
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    handleResetPassword = event => {
        event.preventDefault();

        if (this.validate()) {
            this.setState({
                loading: true
            });

            AuthService.resetPassword(this.state.email, this.state.token, this.state.password, this.state.confirmPassword).then((response) => {
                console.log("reset pw response : ", response);
                let message = response.message || response.message.toString();
                this.setState({
                    message: message,
                    successful: true,
                    loading: false
                });
            }, (error) => {
                console.log("reset pw error : ", error);
                const messageError = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                this.setState({
                    message: messageError,
                    successful: false,
                    loading: false
                });
            });
        }
    }

    showHide(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input'
        })
    }

    render() {
        return (
            <Form id="resetPasswordForm" onSubmit={this.handleResetPassword}>
                <h2 className={styles.formTitle}>Reset Password</h2>
                {this.state.successful ?
                    <>
                        {this.state.message && (
                            <Form.Group>
                                <Alert variant={this.state.successful ? "success" : "danger"}>
                                    {this.state.message}
                                </Alert>
                            </Form.Group>
                        )}
                    </>
                    :
                    <>
                        <Form.Group className="mb-3">
                            {/*<Form.Label>*/}
                            {/*    New Password*/}
                            {/*</Form.Label>*/}
                            <Form.Control
                                type={this.state.type}
                                autoComplete="off"
                                placeholder="New Password"
                                id="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                isInvalid={this.state.password?.length > 0 && (!this.state.validLength || !this.state.hasNumber || !this.state.upperCase || !this.state.lowerCase)}
                                required
                                disabled={this.state.successful}
                            />
                            {this.state.errors.password && <Alert variant="danger">{this.state.errors.password}</Alert>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            {/*<Form.Label>*/}
                            {/*    Confirm New Password*/}
                            {/*</Form.Label>*/}
                            <Form.Control
                                type={this.state.type}
                                autoComplete="off"
                                placeholder="Confirm Password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={this.state.confirmPassword}
                                onChange={this.handleChange}
                                required
                                disabled={this.state.successful}
                            />
                            {this.state.errors.confirmPassword && <Alert variant="danger">{this.state.errors.confirmPassword}</Alert>}
                        </Form.Group>
                        <p>Your password must have <span className={this.state.validLength ? "text-success" : "text-danger"}>8+ characters</span >, <span className={this.state.hasNumber ? "text-success" : "text-danger"}>number</span>, <span className={this.state.upperCase ? "text-success" : "text-danger"}>upper</span> and <span className={this.state.lowerCase ? "text-success" : "text-danger"}>lower</span> case letters</p>
                        <Form.Group>
                            <Form.Control className={styles.formEnterInput}
                                type="hidden"
                                autoComplete="off"
                                id="email"
                                name="email"
                                value={this.state.email}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control className={styles.formEnterInput}
                                type="hidden"
                                autoComplete="off"
                                id="token"
                                name="token"
                                value={this.state.token}
                                required
                            />
                        </Form.Group>

                        {this.state.message && (
                            <Form.Group>
                                <Alert variant={this.state.successful ? "success" : "danger"}>
                                    {this.state.message}
                                </Alert>
                            </Form.Group>
                        )}

                        {!this.state.successful &&
                            <Form.Group>
                                <button type="submit" className={styles.button} disabled={this.state.loading}>
                                {this.state.loading && (
                                    <Spinner animation="border" />
                                    )}
                                    Submit
                                </button>
                        </Form.Group>
                        }
                        {this.props.goToLogin && <Link className={styles.passwordForget} to="" onClick={this.props.goToLogin}>Remembered your password?</Link>}

                    </>
                }
                {this.state.successful &&
                    <Form.Group>
                        <button type="button" onClick={this.props.goToLogin} className={styles.button}>Login into account</button>
                    </Form.Group>
                }
            </Form>
        )
    }
}
export default withRouter(ResetPassword);