import React, { Component, useState, useEffect } from 'react';
import './HostGameView.scss';

export default class FaceOff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            answer: props.correctAnswer,
            FaceOffData: props.FaceOffData || ''
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.FaceOffData !== this.props.FaceOffData) {
            this.setState({ FaceOffData: this.props.FaceOffData });
        }

        //if (prevProps.correctAnswer !== this.props.correctAnswer) {

        //    const correctAnswer = Array.isArray(this.props.correctAnswer)
        //        ? this.props.correctAnswer.join('')
        //        : this.props.correctAnswer;

        //    this.setState({ answer: correctAnswer });
        //}
    }

    getFaceOffPlayer() {
        const player = this.props.players.find(x => x.id === this.props.FaceOffPlayerId);
        console.log("Player : ", player);
        console.log("Face Off Player Id : ", this.props.FaceOffPlayerId);
        if (!player) {
            return null;
        }

        return <div className="face-off-group popIn">
            <img src={this.state.FaceOffData[1]} alt="Face Off Reveal Avatar" className="face-off-image" />
            <div className="face-off-name">{player.name}</div>
        </div>
    }

    render() {
        return (
            <div className="face-off-container">
                {this.state.FaceOffData && (
                    <>
                        <img src={this.state.FaceOffData[0]} alt="Face Off" className="face-off-image" />
                    </>
                )}
                {
                    this.props.showCorrectAnswer &&
                        this.getFaceOffPlayer()
                }
                <ConnectFourLetterBox answer={this.props.correctAnswer} showCorrectAnswer={this.props.showCorrectAnswer} />
            </div>
        );
    }
}

const ConnectFourLetterBox = ({ answer, showCorrectAnswer }) => {
    const [splitAnswer, setSplitAnswer] = useState([]);

    useEffect(() => {
        const formattedAnswer = Array.isArray(answer) ? answer.join('') : answer;
        const slicedWord = typeof formattedAnswer === 'string' ? formattedAnswer.split('') : [];
        setSplitAnswer(slicedWord);
        console.log(slicedWord);
    }, [answer]);

    return (
        <div className={`connect-four-letter-box-container ${showCorrectAnswer ? 'show-correct-answer-bounce' : 'CFLB-start'}`}>
            {splitAnswer.map((letter, index) => (
                <div key={index} className={`connect-four-letter ${letter === ' ' ? 'connect-four-space' : ''}`}>
                    {
                        showCorrectAnswer && (
                            <h1>{letter}</h1>
                        )
                    }
                </div>
            ))}
        </div>
    );
};
