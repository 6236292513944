import React, { Component } from 'react';
import Lottie from 'react-lottie';
import "animate.css";

import getAvatarById from "constants/avatars";
import styles from "components/DefaultViewStyles.module.scss";
import ErrorModal from './Utility/ErrorModal';
import 'components/UserComponents/GameView.scss';



import UCLogo from 'images/UC-Logo.png'


const animations = [
    "bounce",
    "rubberBand",
    "shakeX",
    "shakeY",
    "headShake",
    "swing",
    "tada",
    "wobble",
    "jello",
    "heartBeat",
];

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
};

export default class DefaultView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            player: props.player,
            gameState: null,
            tickedSkipTutorial: false,
            showStartWarning: false,
        };
    }

    componentDidMount() {
        this.props.room.state.ucData.listen("gameState", (currentValue, previousValue) => {
            if (currentValue !== GameStates.Loading && currentValue !== GameStates.EndGame && this.state.showStartWarning) {
                this.setState({ showStartWarning: false });
            }

            this.setState({ gameState: currentValue })
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ player: nextProps.player, });
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    doAnimation = () => {
        let animation = this.getRandomElement(animations);
        this.state.room.send("animate_potato", { animation: animation, });
        if (document.getElementById(`potato`)) {
            this.animateCSS(`#potato`, animation);
        }
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    clickStartGame = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.props.players.length; i++) {
            let value = this.props.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartWarning: false })
            this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
        }
    }


    clickPlayAgain = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.props.players.length; i++) {
            let value = this.props.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartWarning: false })
            this.state.room.send("universally_challenged_new_game", {});
        }
    }

    toggleSkipTutorial = (e) => {
        console.log("cb value: " + e.target.checked);
        this.setState({ tickedSkipTutorial: e.target.checked });
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    confirmStartGame = () => {
        this.clickStartGame(true);
    }

    confirmPlayAgain = () => {
        this.clickPlayAgain(true);
    }

    render() {
        return (
            <div className={`${styles.defaultSection}`}>
                <div className={styles.playerSection}>
                    {
                        this.state.player && this.state.player.id &&
                        <div className="intermission-container">
                            <div className="game-view-header-profile">
                                <div className="game-view-header-profile-icon2">

                                    {
                                        this.state.player.ucData.playerAvatar.length > 2 ?
                                            <img src={this.state.player.ucData.playerAvatar} alt="Avatar" className="header-profile-image" />
                                            :
                                            <div className="header-profile-image" >
                                                <div class="circle-loading" />
                                            </div>

                                    }
                                    <div className="game-view-header-profile-name">
                                        <h3>
                                            {this.state.player.name}
                                        </h3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </div>

                {
                    this.state.player && this.state.player.primaryPlayer && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) && this.props.hostConnected &&
                    <React.Fragment>
                        {
                            this.state.gameState === GameStates.Loading &&
                            <div className={styles.buttons}>
                                {/*<div className={styles.button} onClick={() => this.clickStartGame()}>Start Game</div>*/}
                                {/*<div className={styles.skipBox}>*/}
                                {/*    <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />*/}
                                {/*    <label for="checkbox">Skip Tutorial</label>*/}
                                {/*</div>*/}
                            </div>
                        }
                        {
                            this.state.gameState === GameStates.EndGame &&
                            <div className={styles.buttons}>
                                {/*<div className={`${styles.button} ${styles.alt}`} onClick={() => this.clickPlayAgain()}>Play Again</div>*/}
                            </div>
                        }
                    </React.Fragment>
                }
                {
                    this.state.showStartWarning && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) && this.props.hostConnected &&
                    <ErrorModal
                        title={"Are you ready to play?"}
                        styles={"d-flex"}
                        message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                        callback={this.closeStartWarning}
                        callbackText={"No"}
                        callback2={this.state.gameState === GameStates.Loading ? this.confirmStartGame : this.confirmPlayAgain}
                        callbackText2={"Yes"}
                    />
                }
            </div>
        )
    }
}