// import howler
import { Howl } from 'howler';
import roundMusic from '../audio/MainMenuLoop.mp3'
import IntermissionMusic from '../audio/intermissionLoop.mp3'
// CORRECT ANSWERS
import correctAnswer1 from '../audio/VoRework/ConnectFour/correctAnswer/correct1.mp3'
import correctAnswer2 from '../audio/VoRework/ConnectFour/correctAnswer/correct2.mp3'
import correctAnswer3 from '../audio/VoRework/ConnectFour/correctAnswer/correct3.mp3'
import correctAnswer4 from '../audio/VoRework/ConnectFour/correctAnswer/correct4.mp3'
import correctAnswer5 from '../audio/VoRework/ConnectFour/correctAnswer/correct5.mp3'
import correctAnswer6 from '../audio/VoRework/ConnectFour/correctAnswer/correct6.mp3'
import correctAnswer7 from '../audio/VoRework/ConnectFour/correctAnswer/correct7.mp3'
import correctAnswer8 from '../audio/VoRework/ConnectFour/correctAnswer/correct8.mp3'
import correctAnswer9 from '../audio/VoRework/ConnectFour/correctAnswer/correct9.mp3'
import correctAnswer10 from '../audio/VoRework/ConnectFour/correctAnswer/correct10.mp3'
import correctAnswer11 from '../audio/VoRework/ConnectFour/correctAnswer/correct11.mp3'
import correctAnswer12 from '../audio/VoRework/ConnectFour/correctAnswer/correct12.mp3'
import correctAnswer13 from '../audio/VoRework/ConnectFour/correctAnswer/correct13.mp3'
import correctAnswer14 from '../audio/VoRework/ConnectFour/correctAnswer/correct14.mp3'
import correctAnswer15 from '../audio/VoRework/ConnectFour/correctAnswer/correct15.mp3'
import correctAnswer16 from '../audio/VoRework/ConnectFour/correctAnswer/correct16.mp3'
import correctAnswer17 from '../audio/VoRework/ConnectFour/correctAnswer/correct17.mp3'
import correctAnswer18 from '../audio/VoRework/ConnectFour/correctAnswer/correct18.mp3'
import correctAnswer19 from '../audio/VoRework/ConnectFour/correctAnswer/correct19.mp3'
import correctAnswer20 from '../audio/VoRework/ConnectFour/correctAnswer/correct20.mp3'
import correctAnswer21 from '../audio/VoRework/ConnectFour/correctAnswer/correct21.mp3'
// INCORRECT ANSWERS
import incorrectAnswer1 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect1.mp3'
import incorrectAnswer2 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect2.mp3'
import incorrectAnswer3 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect3.mp3'
import incorrectAnswer5 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect5.mp3'
import incorrectAnswer6 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect6.mp3'
import incorrectAnswer7 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect7.mp3'
import incorrectAnswer8 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect8.mp3'
import incorrectAnswer9 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect9.mp3'
import incorrectAnswer10 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect10.mp3'
import incorrectAnswer11 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect11.mp3'
import incorrectAnswer12 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect12.mp3'
import incorrectAnswer14 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect14.mp3'
import incorrectAnswer15 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect15.mp3'
import incorrectAnswer16 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect16.mp3'
import incorrectAnswer17 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect17.mp3'
import incorrectAnswer18 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect18.mp3'
import incorrectAnswer19 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect19.mp3'
import incorrectAnswer20 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect20.mp3'
import incorrectAnswer21 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect21.mp3'
import incorrectAnswer22 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect22.mp3'
import incorrectAnswer23 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect23.mp3'
import incorrectAnswer24 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect24.mp3'
import incorrectAnswer25 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect25.mp3'
import incorrectAnswer26 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect26.mp3'
import incorrectAnswer27 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect27.mp3'
import incorrectAnswer28 from '../audio/VoRework/ConnectFour/incorrectAnswer/incorrect28.mp3'

// connect four intros
import voConnectFourIntro1 from '../audio/VoRework/ConnectFour/intro/intro1.mp3';
import voConnectFourIntro2 from '../audio/VoRework/ConnectFour/intro/intro2.mp3';
import voConnectFourIntro3 from '../audio/VoRework/ConnectFour/intro/intro3.mp3';
import voConnectFourIntro4 from '../audio/VoRework/ConnectFour/intro/intro4.mp3';
import voConnectFourIntro5 from '../audio/VoRework/ConnectFour/intro/intro5.mp3';

// where in the world intros
import voWhereInTheWorldIntro1 from '../audio/VoRework/WhereInTheWorld/intro/intro1.mp3';
import voWhereInTheWorldIntro2 from '../audio/VoRework/WhereInTheWorld/intro/intro2.mp3';
import voWhereInTheWorldIntro3 from '../audio/VoRework/WhereInTheWorld/intro/intro3.mp3';
import voWhereInTheWorldIntro4 from '../audio/VoRework/WhereInTheWorld/intro/intro4.mp3';
import voWhereInTheWorldIntro5 from '../audio/VoRework/WhereInTheWorld/intro/intro5.mp3';

// face off intros
import voFaceOffIntro1 from '../audio/VoRework/FaceOff/intro/intro1.mp3';
import voFaceOffIntro2 from '../audio/VoRework/FaceOff/intro/intro2.mp3';
import voFaceOffIntro3 from '../audio/VoRework/FaceOff/intro/intro3.mp3';
import voFaceOffIntro4 from '../audio/VoRework/FaceOff/intro/intro4.mp3';
import voFaceOffIntro5 from '../audio/VoRework/FaceOff/intro/intro5.mp3';

// lyric linguist intros
import voLyricLinguistIntro1 from '../audio/VoRework/LyricLinguist/intro/intro1.mp3';
import voLyricLinguistIntro2 from '../audio/VoRework/LyricLinguist/intro/intro2.mp3';
import voLyricLinguistIntro3 from '../audio/VoRework/LyricLinguist/intro/intro3.mp3';
import voLyricLinguistIntro4 from '../audio/VoRework/LyricLinguist/intro/intro4.mp3';
import voLyricLinguistIntro5 from '../audio/VoRework/LyricLinguist/intro/intro5.mp3';

// missing vowels intros
import voMissingVowelsIntro1 from '../audio/VoRework/MissingVowels/intro/intro1.mp3';
import voMissingVowelsIntro2 from '../audio/VoRework/MissingVowels/intro/intro2.mp3';
import voMissingVowelsIntro3 from '../audio/VoRework/MissingVowels/intro/intro3.mp3';
import voMissingVowelsIntro4 from '../audio/VoRework/MissingVowels/intro/intro4.mp3';
import voMissingVowelsIntro5 from '../audio/VoRework/MissingVowels/intro/intro5.mp3';

// movies by monet intros
import voSceneItIntro1 from '../audio/VoRework/MoviesByMonet/intro/intro1.mp3';
import voSceneItIntro2 from '../audio/VoRework/MoviesByMonet/intro/intro2.mp3';
import voSceneItIntro3 from '../audio/VoRework/MoviesByMonet/intro/intro3.mp3';
import voSceneItIntro4 from '../audio/VoRework/MoviesByMonet/intro/intro4.mp3';
import voSceneItIntro5 from '../audio/VoRework/MoviesByMonet/intro/intro5.mp3';

// GAME OVER
import gameOver1 from '../audio/VoRework/gameOver/gameOver1.mp3';
import gameOver2 from '../audio/VoRework/gameOver/gameOver2.mp3';
import gameOver3 from '../audio/VoRework/gameOver/gameOver3.mp3';
import gameOver4 from '../audio/VoRework/gameOver/gameOver4.mp3';
import gameOver5 from '../audio/VoRework/gameOver/gameOver5.mp3';

// upload Avatar
import uploadAvatar1 from '../audio/VoRework/avatarUpload/Avatar1.mp3';
import uploadAvatar2 from '../audio/VoRework/avatarUpload/Avatar2.mp3';
import uploadAvatar3 from '../audio/VoRework/avatarUpload/Avatar3.mp3';
import uploadAvatar4 from '../audio/VoRework/avatarUpload/Avatar4.mp3';
import uploadAvatar5 from '../audio/VoRework/avatarUpload/Avatar5.mp3';

// intro 
import intro1 from '../audio/VoRework/introToGame/Intro1.mp3';
import intro2 from '../audio/VoRework/introToGame/Intro2.mp3';
import intro3 from '../audio/VoRework/introToGame/Intro3.mp3';
import intro4 from '../audio/VoRework/introToGame/Intro4.mp3';
import intro5 from '../audio/VoRework/introToGame/Intro5.mp3';

// team selection
import teamSelection1 from '../audio/VoRework/teamSelection/selectTeam1.mp3';
import teamSelection2 from '../audio/VoRework/teamSelection/selectTeam2.mp3';
import teamSelection3 from '../audio/VoRework/teamSelection/selectTeam3.mp3';
import teamSelection4 from '../audio/VoRework/teamSelection/selectTeam4.mp3';
import teamSelection5 from '../audio/VoRework/teamSelection/selectTeam5.mp3';

// midRound
import midRound1 from '../audio/VoRework/midRound/endRound1.mp3';
import midRound2 from '../audio/VoRework/midRound/endRound2.mp3';
import midRound3 from '../audio/VoRework/midRound/endRound3.mp3';
import midRound4 from '../audio/VoRework/midRound/endRound4.mp3';
import midRound5 from '../audio/VoRework/midRound/endRound5.mp3';
import midRound6 from '../audio/VoRework/midRound/endRound6.mp3';
import midRound7 from '../audio/VoRework/midRound/endRound7.mp3';
import midRound8 from '../audio/VoRework/midRound/endRound8.mp3';
import midRound9 from '../audio/VoRework/midRound/endRound9.mp3';
import midRound10 from '../audio/VoRework/midRound/endRound10.mp3';
import midRound11 from '../audio/VoRework/midRound/endRound11.mp3';
import midRound12 from '../audio/VoRework/midRound/endRound12.mp3';
import midRound13 from '../audio/VoRework/midRound/endRound13.mp3';
import midRound14 from '../audio/VoRework/midRound/endRound14.mp3';
import midRound15 from '../audio/VoRework/midRound/endRound15.mp3';

// small intermission 
import smallIntermission1 from '../audio/VoRework/SmallIntermission/smallIntermission1.mp3'
import smallIntermission2 from '../audio/VoRework/SmallIntermission/smallIntermission2.mp3'
import smallIntermission3 from '../audio/VoRework/SmallIntermission/smallIntermission3.mp3'
import smallIntermission4 from '../audio/VoRework/SmallIntermission/smallIntermission4.mp3'
import smallIntermission5 from '../audio/VoRework/SmallIntermission/smallIntermission5.mp3'


const VO = {
    vo: {
        music: {
            startGameMusic: new Howl({
                src: [roundMusic], volume: 0.1, loop: true
            }),
            intermissionMusic: new Howl({
                src: [IntermissionMusic], volume: 0.25, loop: true
            })
        },
        connectFour: {
            correctAnswer: [
                new Howl({ src: [correctAnswer1], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer2], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer3], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer4], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer5], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer6], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer7], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer8], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer9], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer10], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer11], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer12], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer13], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer14], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer15], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer16], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer17], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer18], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer19], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer20], volume: 1, loop: false }),
                new Howl({ src: [correctAnswer21], volume: 1, loop: false }),

            ],
            incorrectAnswer: [
                new Howl({ src: [incorrectAnswer1], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer2], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer3], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer5], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer6], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer7], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer8], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer9], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer10], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer11], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer12], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer14], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer15], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer16], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer17], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer18], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer19], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer20], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer21], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer22], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer23], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer24], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer25], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer26], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer27], volume: 1, loop: false }),
                new Howl({ src: [incorrectAnswer28], volume: 1, loop: false }),
            ],
        },
        midRoundTalk: {
            midRound: [
                new Howl({ src: [midRound1], volume: 1, loop: false }),
                new Howl({ src: [midRound2], volume: 1, loop: false }),
                new Howl({ src: [midRound3], volume: 1, loop: false }),
                new Howl({ src: [midRound4], volume: 1, loop: false }),
                new Howl({ src: [midRound5], volume: 1, loop: false }),
                new Howl({ src: [midRound6], volume: 1, loop: false }),
                new Howl({ src: [midRound7], volume: 1, loop: false }),
                new Howl({ src: [midRound8], volume: 1, loop: false }),
                new Howl({ src: [midRound9], volume: 1, loop: false }),
                new Howl({ src: [midRound10], volume: 1, loop: false }),
                new Howl({ src: [midRound11], volume: 1, loop: false }),
                new Howl({ src: [midRound12], volume: 1, loop: false }),
                new Howl({ src: [midRound13], volume: 1, loop: false }),
                new Howl({ src: [midRound14], volume: 1, loop: false }),
                new Howl({ src: [midRound15], volume: 1, loop: false }),
            ]
        },
        intermission: {
            smallIntermission: [
                new Howl({ src: [smallIntermission1], volume: 1, loop: false }),
                new Howl({ src: [smallIntermission2], volume: 1, loop: false }),
                new Howl({ src: [smallIntermission3], volume: 1, loop: false }),
                new Howl({ src: [smallIntermission4], volume: 1, loop: false }),
                new Howl({ src: [smallIntermission5], volume: 1, loop: false }),

            ]
        }


    },

    async playRandom(categoryPath) {
        const pathSegments = categoryPath.split('.');
        let audioCategory = this.vo;

        for (let segment of pathSegments) {
            audioCategory = audioCategory[segment];
            if (!audioCategory) {
                console.error(`Invalid audio category: ${categoryPath}`);
                return;
            }
        }

        if (Array.isArray(audioCategory)) {
            if (!audioCategory.length) {
                console.error('No Howl instances available in array:', audioCategory);
                return;
            }
            const randomHowl = audioCategory[Math.floor(Math.random() * audioCategory.length)];
            await randomHowl.play();
        } else {
            console.error('Selected audio category is not an array of Howls:', audioCategory);
        }
    },

    playMusic(categoryPath) {
        console.log("request play startGameMusic hit #2")

        const pathSegments = Array.isArray(categoryPath) ? categoryPath : categoryPath.split('.');
        let audioCategory = this.vo;

        for (let segment of pathSegments) {
            audioCategory = audioCategory[segment];

            if (!audioCategory) {
                console.error(`Invalid audio category: ${categoryPath}`);
                return;
            }
        }

        if (audioCategory instanceof Howl) {

            if (audioCategory.playing()) {
                console.log("Music is already playing, skipping duplicate play request.");
                return;
            }

            audioCategory.play();
        } else {
            console.error(`Target category is not a playable Howl instance: ${categoryPath}`);
        }
    },

    pauseMusic(categoryPath) {
        const pathSegments = Array.isArray(categoryPath) ? categoryPath : categoryPath.split('.');
        let audioCategory = this.vo;

        for (let segment of pathSegments) {
            audioCategory = audioCategory[segment];

            if (!audioCategory) {
                console.error(`Invalid audio category: ${categoryPath}`);
                return;
            }
        }

        if (audioCategory instanceof Howl) {
            audioCategory.pause();
        } else {
            console.error(`Target category is not a playable Howl instance: ${categoryPath}`);
        }
    },

    fadeOutMusic(voRoot, categoryPath, duration = 1500) {
        console.log("requested fade out AAAAAAAAAAAAAAA");

        // Support either a dot-string path ("music.startGameMusic")
        // or an array path (["music", "startGameMusic"]).
        const pathSegments = Array.isArray(categoryPath)
            ? categoryPath
            : categoryPath.split('.');

        let current = voRoot;
        for (const segment of pathSegments) {
            if (!current[segment]) {
                console.error(`Invalid audio category path: ${categoryPath}`);
                return;
            }
            current = current[segment];
        }

        // Now 'current' should be a Howl instance, if valid
        if (current instanceof Howl) {
            if (current.playing()) {
                current.fade(0.15, 0, duration);

                setTimeout(() => {
                    current.stop();
                    current.volume(0.15);
                }, duration);
            } else {
                console.log('Sound is not currently playing, no fade-out needed.');
            }
        } else {
            console.error(`Category is not a Howl instance: ${categoryPath}`);
        }
    }
};

export { VO };
