import React, { Component, createRef } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
//import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
//import Confetti from 'react-confetti';
//import LoggingService from "services/logging";

import Loading from "components/Loading";
import Menu from "components/Menu";
import Player from "components/Player";
//import Tutorial from "components/Tutorial";

//import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/HostStyles.module.scss';

import logo from "images/logo.png";
import UCLogo from 'images/UC-Logo.png'
import mutedIcon from "images/Host/lobby_muted.png";
import unmutedIcon from "images/Host/lobby_unmuted.png";
import fullscreenIcon from "images/Host/lobby_fullscreen.png";
import helpIcon from "images/Host/lobby_help.png";
import tempBrain from 'images/TempBrain.png';
import UCDoor from 'images/UCDoor2.png'
import CategorySelect from './HostComponents/CategorySelect';
import SelectingTeam from './HostComponents/SelectingTeam';
import GameView from './HostComponents/GameView';
import GameOver from './HostComponents/GameOver'
import DefaultView from './HostComponents/DefaultView';
import TeamWidgetContainer from './HostComponents/TeamWidgetContainer'
import HostSpeaking from './HostComponents/HostSpeaking'
import { withRouter } from 'react-router-dom';

import { audio } from 'constants/audio';
import {VO } from 'constants/audioRework'
const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    SelectingInfo: "selecting_info",
    SelectingTeam: "selecting_team",
    SelectingTeamLocked: "selecting_team_locked",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
};

const RoundTypes = {
    Laoding: "loading",
    ConnectFour: "connect_four",
    MissingVowels: "missing_vowels",
    WhereInTheWorld: "where_in_the_world",
    LyricLinguist: "lyric_linguist",
    SpellingBee: "spelling_bee",
    PictureFrame: "picture_frame",
    FaceOff: "face_off",
    SceneIt: "scene_it",
};

const gameRoundStateVars = [
    "ConnectFourData",
    "WhereInTheWorldData",
    "FaceOffData",
    "SpellingBeeData",
    "PictureFrameData",
    "correctAnswer",
];
const gameId = "universally_challenged";


class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            reconnectTries: 0,
            connected: false,
            //connected: true, //Debug
            muted: false,
            menuOpen: false,
            tickedSkipTutorial: false,
            gameBegun: false, // test

            players: [],
            playersRequired: 1,
            showPlayers: true,
            gameState: GameStates.Loading,
            round: RoundTypes.Loading,

            doingTutorial: false,
            showTutorial: false,
            skipTutorialCount: 0,
            showStartButtons: true,
            showPlayAgainButtons: false,

            ConnectFourData: [],
            WhereInTheWorldData: [],
            LyricLinguistData: [],
            SpellingBeeData: [],
            PictureFrameData: [],

            showBlackScreen: false,
            moveLogoToTopLeft: false,
            showTeams: false,

            teamAScore: 0,
            teamBScore: 0,

            roomError: false,

            slamPlayed: false,
        };

        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.GameViewRef = createRef();

    }

    componentDidMount() {
        //this.doReconnect();

        this.toggleMute(true, false);
        if (this.props.auth.isAuthorised) {
            this.startLobby();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.auth.isAuthorised && prevProps.auth.isAuthorised !== this.props.auth.isAuthorised) {
            this.startLobby();
        }
    }

    startLobby() {
        const token = this.getQueryStringValue("token");
        this.initAudio();
        if (token) {
            this.doReconnect();
        } else {
            this.doCreate();
        }
    }

    initAudio() {
        console.log("Init audio");
        this.preloadAudio();
        Howler.volume(0.5);
        VO.playMusic('music.startGameMusic')
        console.log("request play startGameMusic")
        //audio.roundTestMusic.loaded?.play();
        //audio.BgMusic.loaded.play();
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen === true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = !this.state.muted;
        }

        audio.sfxClick1.loaded?.play();

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    logOut = () => {
        this.props.onLogout();
        Howler.stop();
    }

    stopHosting = () => {
        this.props.onStopHosting();
        this.state.room.leave(true);
        Howler.stop();
    }

    checkAndAddPlayer(player) {
        console.log("New Player with Id : ", player.id);
        if (!this.state.players.find(elem => elem.id === player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
            console.log("NEW PLAYERS: ", this.state.players);
        }
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id !== id), }
        });
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    signalStartGame = () => {
        console.log("signalStartGame hit")
        this.setState({ slamPlayed: false })
        audio.sfxZoomOut.loaded?.play();


        this.setState({ showStartButtons: false });
        this.state.room.send("begin_game", { skipTutorial: true, });
        //this.setState({ showBlackScreen: true, moveLogoToTopLeft: true });
       
        //setTimeout(() => {
            //this.setState({ showStartButtons: false });
            //this.state.room.send("begin_game", { skipTutorial: true, });
        //}, 1500)


        //setTimeout(() => {
        //    this.setState({ showBlackScreen: false });
        //}, 3000);

    }
    goToLobby = () => {
        this.state.room.send("change_game", {});
    }
    signalNewGame = () => {
        this.setState({ showPlayAgainButtons: false, showWinnerSection: false, });
        setTimeout(() => {
            this.state.room.send("uc_new_game", {});
        }, 1000);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name !== "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartButtons) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    setupStateListeners = (roomState) => {
        roomState.players.onAdd((player, key) => {
            console.log(JSON.stringify(player), "has been added at", key);
            this.checkAndAddPlayer(player);

            const changes = ["connected", "votedSkip", "avatar", "name", "id", "primaryPlayer", "connectingTimer"];
            changes.forEach(change => {
                player.listen(change, (value) => {
                    console.log(`Player ${player.name} Change ${change} to Value ${value}`);
                    this.setState((prevState) => {
                        return {
                            players: prevState.players.map((x) => {
                                if (x.id === player.id) {
                                    return { ...x, [change]: value };
                                }
                                return x;
                            })
                        }
                    });
                });
            });

            const ucPlayerChanges = ["hasSubmittedAnswer", "playerAvatar", "playerAge",
                "hasUploadedAvatar", "faceOffAvatar", "faceOffName",
                "readyToSelectSub", "startReady", "team", "starbucks",
                "answersCorrect", "submittedAnswer", "answerCorrect", "whereInTheWorldColor",
                "whereInTheWorldDistance"];
            ucPlayerChanges.forEach(change => {
                player.ucData.listen(change, (value) => {
                    if (change === "team") {
                        audio.sfxClick1.loaded?.play();
                    }
                    if (change === "hasSubmittedAnswer" && value === true) {
                        audio.sfxClick1.loaded?.play();
                    }
                    if (change === "hasUploadedAvatar") {
                        !value && setTimeout(() => {
                            audio.sfxGlitch2.loaded?.play();

                        }, 1200)
                    }
                    this.setState((prevState) => {
                        return {
                            players: prevState.players.map((x) => {
                                if (x.id === player.id) {
                                    return { ...x, ucData: { ...x.ucData, [change]: value } };
                                }
                                return x;
                            })
                        }
                    });
                });
            });

            const ucPlayerArrayChanges = ["whereInTheWorldCoords"]
            ucPlayerArrayChanges.forEach(change => {
                player.ucData[change].onAdd((value, index) => {
                    this.setState((prevState) => {
                        // overwrite the array at the index
                        let newArray = [...prevState.players.find(x => x.id === player.id).ucData[change]];
                        newArray[index] = value;
                        return {
                            players: prevState.players.map((x) => {
                                if (x.id === player.id) {
                                    return { ...x, ucData: { ...x.ucData, [change]: newArray } };
                                }
                                return x;
                            })
                        }
                    });
                })
                player.ucData[change].onRemove((value, index) => {
                    this.setState((prevState) => {
                        return {
                            players: prevState.players.map((x) => {
                                if (x.id === player.id) {
                                    return { ...x, ucData: { ...x.ucData, [change]: x.ucData[change].map((x, i) => { if (i === index) { return null } return x }) } };
                                }
                                return x;
                            })
                        }
                    });
                })
            });

        });

        roomState.players.onRemove((player, key) => {
            console.log(JSON.stringify(player), "has been removed at", key);
                this.removePlayer(player.id);
            });

        /* Example game state listeners */
        const ucStateVars = ["gameState", "round", "teamAScore", "teamBScore",
            "timer", "timerRunning", "LyricLinguistData", "VanishingVowelsData",
           "SceneItData", "roundOver", "intermission", "currentSubRound",
            "answerType", "teamALocked", "teamBLocked", "buzzedInPlayerId",
            "teamACorrect", "teamBCorrect", "hintsRevealed", "isBuzzTimer", "buzzTimer",
            "FaceOffPlayerId"];
        ucStateVars.forEach((stateVar) => {
            roomState.ucData.listen(stateVar, (change) => {
                //stateVar !== "timer" && console.log(`UCData change: ${stateVar} --- ${change}`);
                let update = {};
                console.log("change: ", change)
                let allPLayersInTeam = this.state.players.every(player => player.ucData.team);
                if (!this.state.showStartButtons && allPLayersInTeam && !this.state.slamPlayed) {
                    console.log("shush")
                    audio.sfxSlam.loaded?.play()
                    this.setState({ slamPlayed: true })
                    VO.fadeOutMusic(VO.vo, 'music.startGameMusic', 750)
                } 

                if (stateVar === "currentSubRound") {
                    console.log("dwaudhwauihduiwhdui: ", change)
                }

                

                if (this.state.gameState === GameStates.Playing) {
                    //if ((stateVar === "teamACorrect" || stateVar === "teamBCorrect") && change === true) {
                    //    audio.sfxCorrectAnswer3.loaded?.play();
                    //}
                    //if (stateVar === "teamALocked" && change === true && this.state.teamACorrect === false) {
                    //    audio.sfxIncorrectAnswer1.loaded?.play();
                    //}
                    //if (stateVar === "teamBLocked" && change === true && this.state.teamBCorrect === false) {
                    //    audio.sfxIncorrectAnswer1.loaded?.play();
                    //}

                    if (stateVar === "buzzedInPlayerId" && change !== "") {
                        audio.sfxBuzzedIn.loaded?.play();
                        audio.sfxClockTicking.loaded?.pause();
                    }

                    if (stateVar === "timer") {
                        console.log("TIMER HIT : ", change);
                        if (change === 4) {
                            audio.sfxClockTicking.loaded?.play();
                        } else if (change < 4 && change > 0) {
                            if (!audio.sfxClockTicking.loaded?.playing()) {
                                audio.sfxClockTicking.loaded?.play();
                            }
                        } else if (change === 0) {
                            //audio.sfxTimesUp.loaded?.play();
                            //setTimeout(() => {
                            //    audio.voTimesUp.loaded?.play();
                            //}, 500);
                        }
                    }

                    if (stateVar === "timerRunning" && change === false) {
                        audio.sfxClockTicking.loaded?.stop();
                    }
                }

                if (change === "selecting_team") {
                    this.setState({ showTeams: true })
                    update[stateVar] = change;
                    audio.voGetIntoTeams.loaded?.play();
                }

                if (stateVar === "correctAnswer" && Array.isArray(change)) {
                    update[stateVar] = change.join(", "); // Example: converting the array to a comma-separated string
                } else {
                    update[stateVar] = change;
                }

                this.setState(update);
            });
        });

        gameRoundStateVars.forEach(arrayName => {
            let update = {};
            update[arrayName] = [];
            this.setState(update);
            roomState.ucData[arrayName].onAdd((change, index) => {
                console.log(`UC Array ${arrayName} Change ${change} at index ${index}`);
                this.setState((prevState) => {
                    // overwrite the array at the index
                    let newArray = [...prevState[arrayName]];
                    newArray[index] = change;
                    return {
                        [arrayName]: newArray
                    }
                });
            });
            roomState.ucData[arrayName].onRemove((change, index) => {
                console.log(`UC Array ${arrayName} Removed ${change} at index ${index}`);
                // set index to null
                this.setState((prevState) => {
                    return {
                        [arrayName]: prevState[arrayName].map((x, i) => {
                            if (i === index) {
                                return null;
                            }
                            return x;
                        })
                    }
                });
            });
        });
    }


    doReconnect = () => {
        const token = this.getQueryStringValue("token");

        if (this.state.reconnectTries < 5 && token) {
            if (this.state.connected === false) {
                this.client.reconnect(token).then(room => {
                    this.initRoom(room);
                }).catch(e => {
                    console.log("RECONNECT ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.doCreate();
        }
    }

    doCreate(force = false) {
        // this is the function that creates the room
        if (this.state.connected === false || force) {
            this.client.create("game_city_room", { accessToken: this.props.auth.user?.token, email: this.props.auth.user?.email}).then(room => {
                this.initRoom(room);
            }).catch(e => {
                console.log("CREATE ERROR", e);
                this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                setTimeout(() => {
                    this.doCreate();
                }, 1000);
            });
        }
    }

    initRoom(room) {
        console.log(room.sessionId, "joined", room.name);
        this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken });
        this.updateToken(room.reconnectionToken);
        room.send("update_host_token", { reconnectionToken: room.reconnectionToken });
        audio.voMainIntro.loaded?.play();

        room.onStateChange.once((roomState) => {
            console.log("this is the first room state!", roomState);
            if (roomState.host.id !== room.sessionId) room.leave(true);
            this.setState({ roomState: roomState, });

            console.log("Game State: ", roomState.ucData.gameState);
            if (roomState.ucData.gameState !== GameStates.Loading) {
                room.leave(true);
            } else {
                room.send("host_joined_game", { gameId });
                this.setupStateListeners(roomState);
            }
        });

        room.onStateChange((state) => {
            this.setState({ roomState: state, });
        });

        room.onMessage("animate_potato", (message) => {
            console.log("animate_potato", "received on", room.name, message);
            this.animatePotato(message.id, message.animation);
        });

        room.onMessage("start_game", (message) => {
            audio.voMainIntro.loaded?.stop();
            room.send("start_round", {})
        })

        room.onMessage("begin_tutorial", (message) => {
            console.log("begin_tutorial", "received on", room.name, message);
            this.setState({ showTutorial: true, showStartButtons: false, showPlayers: false, });
            this.state.room.send("show_tutorial", {});
        });

        room.onMessage("end_tutorial", (message) => {
            console.log("end_tutorial", "received on", room.name, message);
            this.setState({ showTutorial: false, showPlayers: true, });
        });

        room.onMessage("clicked_begin_game", (message) => {
            this.setState({ showStartButtons: false, });
        });

        room.onMessage("begin_game", (message) => {
            console.log("begin_game", "received on", room.name, message);
            console.log("game state... ", this.state.roomState.ucData.gameState);
            //if ((this.state.roomState.ucData.gameState === GameStates.Loading || this.state.roomState.ucData.gameState === GameStates.Idle) && !this.state.gameBegun) {
            console.log("begin_game checks passed.")
            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, });
            room.send("start_uc_game", {});
            //}
        });


        room.onMessage("change_game", (message) => {
            console.log("change_game", "received on", room.name, message);
            this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}/?token=${this.state.reconnectionToken}` });
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
            this.state.room.leave(false);
        });

        room.onMessage("new_game", (message) => {
            console.log("new_game", "received on", room.name, message);

        });

        room.onMessage("all_players_ready", (message) => {
            console.log("all_players_ready")
        })

        room.onMessage("game_over", (message) => {
            console.log("game_over", "received on", room.name, message);

        });
        room.onMessage("game_restarted", (message) => {
            console.log("restart game hit")
            this.setState({
                showStartButtons: true, gameBegun: false, showBlackScreen: false, moveLogoToTopLeft: false
            })
            //this.setState({
            //    gameBegun: true, showBlackScreen: false, 
            //})
        });

        room.onMessage('all_players_in_team', (message) => {
            room.send('start_team_timer');
        })

        room.onMessage('end_team_selection', (message) => {

            console.log("end_team_selection hit in home");
            this.setState({}, () => {
                console.log("gameView", this.GameViewRef.current);
                this.GameViewRef.current?.doStartGame();
            });
        });


        room.onError((code, message) => {
            console.log(this.client.id, "couldn't join", room.name);
            //LoggingService.logError(message, code);
            this.setState({ roomError: true, connected: false, room: null });
            this.doCreate();
        });

        room.onLeave((code) => {
            console.log(this.client.id, "left", room.name);
            this.setState({ roomError: true, connected: false, room: null });
            this.doCreate();
        });
    }

    getRenderView() {

        switch (this.state.gameState) {
            case 'uploading_avatar':
                return this.getHostSpeakingView(this.state.gameState);
            case 'selecting_info':
                return this.getUserCategoryView(this.state.gameState);
            case 'selecting_team':
            case 'selecting_team_locked':
                return this.getSelectingTeamView(this.state.gameState);
            case 'playing':
                break;
                return this.getGameView(this.state.gameState)
            case 'host_speaking':
                return this.getHostSpeakingView(this.state.gameState);
            case 'game_over':
                return this.getGameOverView(this.state.gameState);
            default:
                return this.getHostSpeakingView(this.state.gameState);
            //default:
            //    return this.getGameView()
        }
    }

    getUserCategoryView(gameState) {
        console.log(gameState)
        return <HostSpeaking room={this.state.room} audio={audio} players={this.state.players} gameState={gameState} />
    }

    getHostSpeakingView(gameState) {
        console.log(gameState)

        return <HostSpeaking room={this.state.room} audio={audio} players={this.state.players} gameState={gameState} />
    }

    getSelectingTeamView(gameState) {
        console.log(gameState)

        var showText = "";

        if (this.state.timer) {
            if (this.state.timer === 4) {
                showText = "3";
            } else if (this.state.timer === 3) {
                showText = "2";
            } else if (this.state.timer === 2) {
                showText = "1";
            } else if (this.state.timer === 1) {
                showText = "Let's Play!";
            } 
        }

        return <HostSpeaking room={this.state.room} audio={audio} players={this.state.players} gameState={gameState} timer={0} textOverride={showText} />
    }

    getGameOverView(gameState) {
        return <GameOver room={this.state.room} audio={audio} VO={VO} players={this.state.players} gameState={gameState} teamAScore={this.state.teamAScore} teamBScore={this.state.teamBScore} round={this.state.round } />
    }
    getGameView(gameState) {
        const showGameView = this.state.gameState === "playing";
        return (
            <>

                <GameView ref={this.GameViewRef} room={this.state.room} audio={audio} timer={this.state.timer} players={this.state.players}
                    round={this.state.round} currentSubRound={this.state.currentSubRound} ConnectFourData={this.state.ConnectFourData}
                    WhereInTheWorldData={this.state.WhereInTheWorldData} LyricLinguistData={this.state.LyricLinguistData}
                    SpellingBeeData={this.state.SpellingBeeData} VanishingVowelsData={this.state.VanishingVowelsData}
                    PictureFrameData={this.state.PictureFrameData} FaceOffData={this.state.FaceOffData} SceneItData={this.state.SceneItData}
                    correctAnswer={this.state.correctAnswer} teamAScore={this.state.teamAScore} teamBScore={this.state.teamBScore}
                    roundOver={this.state.roundOver} teamACorrect={this.state.teamACorrect} teamBCorrect={this.state.teamBCorrect}
                    hintsRevealed={this.state.hintsRevealed} isBuzzTimer={this.state.isBuzzTimer} buzzTimer={this.state.buzzTimer}
                    answerType={this.state.answerType} show={showGameView} FaceOffPlayerId={this.state.FaceOffPlayerId} gameState={gameState}
                    VO={VO } buzzedInPlayerId={this.state.buzzedInPlayerId} />
                <HostSpeaking room={this.state.room} audio={audio} players={this.state.players} gameState={gameState} show={showGameView} />
            </>
        )

    }

    getDefaultView() {
        return <DefaultView room={this.state.room} players={this.state.players} />
    }

    closeMenu() {
        this.setState({menuOpen: false})
    }

    chunkArray(array, size) {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    }

    render() {

        const allPlayersConnected = this.state.players.every(player => player.connected);
        const minPlayersFullfilled = this.state.players.length >= this.state.playersRequired;
        const allPlayersHaveAvatar = this.state.players.every(player => player.ucData?.hasUploadedAvatar);

        const unplacedPlayers = this.state.players.filter(player => !player.ucData.team);
        const rows = this.chunkArray(unplacedPlayers, 4);
        const allPlayersInTeam = this.state.players.every(player => player.ucData.team);

        return (
            <div>
                <audio ref />
                <div
                    id="gameContainer"
                    className={`${styles.gameContainer}`}
                >

                    <div className={`${!this.state.showStartButtons ? styles.mainBackground : styles.mainBackgroundZoom}`} >
                      
                    </div>
                    {
                        !this.state.showStartButtons ? (
                            <div className={styles.backgroundShadow} />
                        ) : null
                    }
                    {allPlayersInTeam && this.state.gameBegun && (
                        <div className="uc-door-wrapper">
                            <div className="uc-door-mask">
                                <img src={UCDoor} alt="ucDoor" className="uc-door" />
                            </div>
                        </div>

                    ) }
                  
                    <>
                        <div className={`noise-wrapper ${!this.state.showStartButtons ? 'zoom' : ''}`}>
                            <div className="noise" />
                            {
                                this.state.players.length === 0 && (
                                    <div className="brain-container">
                                        <h1>Waiting for players...</h1>
                                       
                                    </div>
                                )
                            }
                            {
                                this.state.gameState !== "game_over" && (

                                    <div className="unplaced-teams">
                                        {rows.map((row, rowIndex) => (
                                            <div key={`row-${rowIndex}`} className="unplaced-players-grid">
                                                {row.map(player => (
                                                    <div key={player.id} className="unplaced-player animated-player">
                                                        <div className="player-icon2">
                                                            {player.ucData.playerAvatar.length > 2 ? (
                                                                <img
                                                                    src={player.ucData.playerAvatar}
                                                                    className="player-icon-image2"
                                                                    alt="Av"
                                                                />
                                                            ) : (
                                                                <div className="header-profile-image">
                                                                    <div className="circle-loading2" />
                                                                </div>
                                                            )}
                                                            <div className="player-icon-name-container2">
                                                                <h3>{player.name}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )
                            }

                        </div>
                    </>

                    {!this.state.connected && <img src={UCLogo} className={styles.loadingLogo} alt="logo" />}
                   
                    
                    {/*{*/ } 
                    {/*    this.state.connected && (*/}
                    {/*        <img*/}
                    {/*            src={UCLogo}*/}
                    {/*            className={`${styles.UCLogo} ${this.state.moveLogoToTopLeft && styles.UCLogoMoveToTopLeft}`}*/}
                    {/*            alt="logo"*/}
                    {/*        />*/}
                    {/*    )*/}
                    {/*}*/}
                    { window.location.hostname === "localhost" &&
                        <div className="test-buttons" >
                            <button onClick={() => this.doCreate(true)}>new lobby</button>
                            <button onClick={() => this.logOut()}>log out</button>
                            <button onClick={() => this.state.room.leave(true)}>close lobby</button>
                            <span style={{ padding: '0 1vh' }} />
                            <button onClick={() => this.state.room.send("next_round", { round: this.state.round })}>next round</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "host_speaking" })}>host_speaking</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "uploading_avatar" })}>upload_avatar</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "selecting_info" })}>selecting_info</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "selecting_team" })}>selecting_team</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "playing" })}>playing</button>
                            <button onClick={() => this.state.room.send("change_state", { data: "idle" })}>idle</button>
                            <button onClick={() => this.state.room.send("change_state", { data: 'game_over' })} >End</button>
                            <span style={{ padding: '0 1vh' }} />
                            <button onClick={() => this.state.room.send("set_round", { data: "connect_four" })}>connectFour</button>
                            <button onClick={() => this.state.room.send("set_round", { data: "missing_vowels" })}>missingVowels</button>
                            <button onClick={() => this.state.room.send("set_round", { data: "where_in_the_world" })}>whereInTheWorld</button>
                            <button onClick={() => this.state.room.send("set_round", { data: "lyric_linguist" })}>lyricLinguist</button>
                            <button onClick={() => this.state.room.send("set_round", { data: "face_off" })}>faceOff</button>
                            <button onClick={() => this.state.room.send("set_round", { data: "scene_it" })}>sceneIt</button>
                            <p id="dbg_text" style={{ color: 'white', width:'min-content' }}>DEBUG</p>
                        </div>
                        //:
                        //<div className="test-buttons" >
                        //    <button onClick={() => this.doCreate(true)}>new lobby</button>
                        //    <button onClick={() => this.logOut()}>log out</button>
                        //    <button onClick={() => this.props.state.leave(true)}>close lobby</button>
                        //</div>
                    }
                    {
                        this.state.connected &&
                            <React.Fragment>
                                <Menu room={this.state.room} toggleMute={this.toggleMute} onLogOut={this.logOut} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} onStopHosting={this.stopHosting} closeMenu={() => this.closeMenu()} />
                                {/*{*/}
                                {/*    this.state.showTutorial &&*/}
                                {/*    <Tutorial room={this.state.room} players={this.state.players} />*/}
                                {/*}*/}
                                {/*<div style={{ zIndex: 20, }}>*/}
                                {/*    {*/}
                                {/*        this.state.doConfetti &&*/}
                                {/*        <Confetti*/}
                                {/*            width={window.innerWidth}*/}
                                {/*            height={window.innerHeight}*/}
                                {/*            initialVelocityY={20}*/}
                                {/*            numberOfPieces={1500}*/}
                                {/*            recycle={false}*/}
                                {/*            confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}*/}
                                {/*            initialVelocityY={{ min: -10, max: -30, }}*/}
                                {/*            initialVelocityX={{ min: -10, max: 10, }}*/}
                                {/*            onConfettiComplete={() => this.setState({ doConfetti: false, })}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>uctv.ai</div>
                                        <div className={styles.text}>Code: <a href={`https://${window.location.host}/client/?qrCode=${this.state.roomId}`} className={`${styles.text} ${styles.code}`} target="_blank" rel="noopener noreferrer" >{this.state.roomId}</a></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} alt="mute" />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} alt="fullscreen" />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} alt="help" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`https://${window.location.host}/client/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.instruction} ${this.state.connected && !this.state.gameBegun && styles.show}`}>
                                    <h1>Instructions</h1>
                                    <p>Grab your phone & scan the QR code</p>
                                    <p>Type in your name and click Join</p>
                                    <p>Create an avatar by taking a selfie</p>
                                    
                                    <p>When everyone's in, click START GAME</p>
                                </div>
                                <div className={`${styles.logoSection} ${this.state.connected ? styles.topLeft : ""}`}>
                                    <img src={logo} className={`${styles.logo}`} alt="logo" />
                                </div>
                                {
                                    this.state.showStartButtons &&
                                    <>
                                    <div className={styles.startButtonSection}>
                                            <div className={`${styles.mainButtonWrapper} ${(!allPlayersConnected || !minPlayersFullfilled || !allPlayersHaveAvatar) && styles.disabled}`}>
                                                <button className={`${styles.mainButton}`} onClick={this.signalStartGame} disabled={!allPlayersConnected || !minPlayersFullfilled || !allPlayersHaveAvatar} >
                                                Start Game
                                            </button>
                                        </div>                                        
                                        </div>
                                    </>
                                }
                                <div className={`${styles.playerColumn} ${styles.left} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.filter((x, index) => index % 2 === 0).map((x) => {
                                            return <Player player={x} left={true} />
                                        })
                                    }
                                </div>
                                <div className={`${styles.playerColumn} ${styles.right} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.filter((x, index) => index % 2 === 1).map((x, index) => {
                                            return <Player player={x} />
                                        })
                                    }
                                </div>
                                {
                                    this.state.showPlayAgainButtons &&
                                    <div className={styles.playAgainButtonSection}>
                                        <button className={`${styles.button}`} onClick={this.signalNewGame}>
                                            Play Again
                                        </button>
                                        {/*<button className={`${styles.button}`} onClick={this.goToLobby}>*/}
                                        {/*    Return to lobby*/}
                                        {/*</button>*/}
                                    </div>
                                }
                                {
                                    !this.state.showStartButtons && (
                                        <div className="host-main-game-container">
                                            {
                                                !this.state.showStartButtons && this.state.showTeams && this.state.gameState !== 'game_over' && (
                                                    <TeamWidgetContainer room={this.state.room} players={this.state.players}
                                                        teamAScore={this.state.teamAScore} teamBScore={this.state.teamBScore}
                                                        round={this.state.round} buzzedInPlayerId={this.state.buzzedInPlayerId}
                                                        answerType={this.state.answerType} teamALocked={this.state.teamALocked}
                                                        teamBLocked={this.state.teamBLocked} teamACorrect={this.state.teamACorrect}
                                                        teamBCorrect={this.state.teamBCorrect} roundOver={this.state.roundOver}
                                                        audio={audio} />
                                                ) 
                                            }
                                            
                                            {this.getRenderView()}
                                        </div>

                                    )
                                }
                                <div className="host-main-game-container" style={{ pointerEvents: "none", touchAction: "none" }}>

                                    {this.getGameView(this.state.gameState)}
                                </div>
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(withRouter(Home));



const PlayerList = ({ players, showStartButtons }) => {
    const playerCount = players.length;
    const playerCountClass = `playerCount${playerCount}`;

    return (
        <div
            className={`${styles.playerList} ${!showStartButtons ? styles.zoom : ''
                } ${styles[playerCountClass]}`}  
        >
            {playerCount === 0 && (
                <h1 style={{ color: 'white' }}>Waiting for players to join...</h1>
            )}

            {players.map((player, index) => (
                <div className={styles.playerContainerBlink}>
                    <div key={index} className={styles.player}>
                        {player?.ucData?.playerAvatar?.length > 2 ? (
                            <img
                                src={player.ucData.playerAvatar}
                                alt="Avatar"
                                className={styles.image}
                            />
                        ) : (
                            <div className={styles.image}>
                                {player.ucData.hasUploadedAvatar ? (
                                    <div className={styles.textAnimator}>
                                        <div className={`${styles.text} ${styles.text1}`}>AI</div>
                                        <div className={`${styles.text} ${styles.text2}`}>CREATING</div>
                                        <div className={`${styles.text} ${styles.text3}`}>AVATAR</div>
                                    </div>
                                ) : (
                                    <div className="circle-loading" />
                                )}
                            </div>
                        )}

                        {!player.connected && (
                            <h3 className={styles.reconectionTimmer}>{player.connectingTimer}</h3>
                        )}

                        <div className={styles.name}>
                            <h3>{player.connected ? player.name : 'Disconnected'}</h3>
                        </div>
                    </div>
                </div>
                
            ))}
        </div>
    );
};
