// HostSpeaking.js
import React, { Component } from 'react';
import './HostGameView.scss';
import FooterToaster from './FooterToaster';
import HeaderToaster from './HeaderToaster';
import tempBrain from '../../images/TempBrain.png'

export default class HostSpeaking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            gameState: props.gameState,
            showHeaderToaster: false,
            showFooterToaster: false,
            headerToasterMessage: "HELLO",
            footerToasterMessage: "Welcome to Universally Challenged, Please update your profile so we can make this game both equally challenging, and fun!",
            outlinedText: "",
            showOutlinedText: false,
            wobble: false,
            animationClass: '',
            showHostBrain: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.gameState !== this.props.gameState) {
            this.setState({ gameState: this.props.gameState }, () => {
                if (this.props.gameState === "playing") {
                    this.setState({
                        showFooterToaster: false,
                        showHeaderToaster: false,
                        showOutlinedText: false
                    })
                } else {
                    this.updateToasterMessages();

                }
            });

           
        }

        if(!prevProps.textOverride && this.props.textOverride) {
            //this.setState({ showOutlinedText: false });
        }
    }

    componentDidMount() {
        if (this.props.gameState === "playing") {
            this.setState({
                showFooterToaster: false,
                showHeaderToaster: false,
                showOutlinedText: false,
                showHostBrain: true 
            })
        } else {
            this.setState({ showHostBrain: true })

            setTimeout(() => {

                this.updateToasterMessages();

            }, 2000)
        }
        
    }

    updateToasterMessages() {
        const { gameState } = this.state;
        let newMessage = "";

        this.setState({ animationClass: 'slide-down fade-out', wobble: true, showOutlinedText: true });
        
        setTimeout(() => {
            this.setState({
                showFooterToaster: true,
                showHeaderToaster: true,
                showOutlinedText: true,

            });
            switch (gameState) {
                case 'selecting_info':
                    newMessage = "Selecting Info";
                    this.setState({
                        headerToasterMessage: newMessage,
                        footerToasterMessage: "Please select the information you would like to use in the game",
                        outlinedText: "Selecting Info"
                    });
                    break;
                case 'selecting_team':
                    newMessage = "Pick a team";
                    this.setState({
                        headerToasterMessage: newMessage,
                        footerToasterMessage: "Tap Team A or B on your device.",
                        outlinedText: "",
                    });
                    break;
                case 'selecting_team_locked':
                    newMessage = "Teams locked in!";
                    this.props.audio.voTeamsSelected.loaded?.play();
                    this.props.audio.voGetIntoTeams.loaded?.stop();
                    this.setState({
                        headerToasterMessage: newMessage,
                        footerToasterMessage: "Let's get ready to play.",
                        outlinedText: "",
                    });
                    break;
                case 'host_speaking':
                    newMessage = "HELLO";
                    this.setState({
                        headerToasterMessage: newMessage,
                        footerToasterMessage: "Welcome to Universally Challenged, Please update your profile so we can make this game both equally challenging, and fun!",
                        outlinedText: "UPDATE YOUR PROFILE"
                    });
                    break;
                case 'uploading_avatar':
                    newMessage = "Upload Avatar";
                    this.setState({
                        headerToasterMessage: newMessage,
                        footerToasterMessage: "Please upload a picture of yourself. This will be turned into a cartoon avatar personalised for you!",
                        outlinedText: "UPLOAD YOUR PICTURE!"
                    });
                    break;

                default:
                    this.setState({
                        showFooterToaster: false,
                        showHeaderToaster: false,
                        showOutlinedText: false

                    });
                    break;
            }
            this.setState({ animationClass: 'fade-in slide-up' });

            setTimeout(() => {
                this.setState({ animationClass: '', wobble: false });
            }, 300);
        }, 300);  
    }

    renderTextDiv(text, additionalStyles) {
        return <>
            <div className={`bold-text-outline  ${this.state.animationClass}`}>{text}
                <div className={'bold-text'}>{text}

                </div>
            </div>
        </>
    }

    render() {
        let show = true;
        if (this.props.show != null) {
            show = this.props.show ? "block" : "none";
        }
        return (
            <div className="host-speaking-container" style={{ "display": show }}>
                {/*<div className="noise-wrapper">*/}
                {/*    <div className="noise" />*/}
                {/*    <img src={tempBrain} alt="Host Brain" className="host-brain-image-static" />*/}
                {/*</div>*/}
                {this.state.showHeaderToaster && (
                    <HeaderToaster
                        wobble={this.state.wobble}
                        message={this.state.headerToasterMessage}
                        timer={this.props.timer}
                    />
                )}

                <div className="host-speaking-text-container">
                    {this.state.showOutlinedText  &&
                    this.renderTextDiv(this.state.outlinedText)}
                </div>
                
                {this.props.textOverride &&
                    <div className={`host-text-overrid`} >
                        {this.renderTextDiv(this.props.textOverride)}
                    </div>
                }

                {/*{*/}
                {/*    this.state.showHostBrain && (*/}
                {/*        <div className="host-brain-container">*/}

                {/*            <img src={tempBrain} alt="Host Brain" className="host-brain-image" />*/}
                {/*            <div className="host-brain-spotlight" />*/}
                {/*            <div className="host-brain-spotlight-floor" />*/}
                {/*            <div className="host-brain-shadow" />*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}

                {this.state.showFooterToaster && (
                    <FooterToaster
                        message={this.state.footerToasterMessage}
                        animationClass={this.state.animationClass}
                    />
                )}
            </div>
        );
    }
}
