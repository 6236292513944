import React, { useState, useEffect } from 'react'
import './WebsiteLanding.scss'
import TV from './images/tv2.svg'
import Laptop from './images/laptop.svg'
import cable from './images/cable.svg'
import logo from './images/PlaceHolderLogo.png'

export default function Help() {

    return (
        <div className="help-container">
        <div className="vignette-overlay"/> 
            <div className="scroll-container scroll-help">
                <button className="back-button" onClick={() => window.location.href = "/"}>
                    Back
                </button>
                <h1>Universally Challenged - Help</h1>

                <div className="help-illustration-container">
                    <h1>
                        You will need: 
                    </h1>
                    <div className="container-row">
                        <div>
                            <img src={TV} alt="tv" />
                            <h2>A Smart TV</h2>
                        </div>
                        <div>
                            <h2>And/or  </h2>
                        </div>
                        <div>
                            <img src={Laptop} alt="tv" />
                            <h2>A Laptop</h2>
                        </div>
                        <div>
                            <h2>+</h2>
                        </div>
                        <div>
                            <img src={cable} alt="tv" />
                            <h2>An HDMI Cable</h2>
                        </div>
                    </div>
                  
                </div>
                <div>
                    <h1>1. Getting your game on the big screen</h1>
                    <div className="divider" />
                    <h4>USING A LAPTOP AND HDMI CABLE</h4>
                    <h4>
                        For this step, you'll need to manually plug your laptop into the back of your TV, Here's what to do
                    </h4>
                    <h5>
                        1.  Plug one end of the HDMI cable into your laptop.
                    </h5><h5>
                        2.  Plug the other end of the cable into the HDMI socket in the back (or side) of the TV
                    </h5><h5>
                        3.  Switch on your TV, Your laptop should now be visible on one of the HDMI channels.
                    </h5><h5>
                        4.  On your laptop, go to uctv.ai and select start game
                    </h5>

                </div><div>
                    <h1>2. Getting Everyone To Join The Game</h1>
                    <div className="divider" />
                    <h4>
                        Now that you're up on the big screen, it's time to get the players involved!
                    </h4>
                    <h5>
                        1. To join, scan the QR code with your smartphone or visit uctv.ai and select join a game and enter the Room ID found on the left of the host.
                    </h5><h5>
                        2. Make up a nickname, choose a tato, and wait for the other players to join.
                    </h5><h5>
                        3. The VIP player can pick a game to get started, eveyone else can vote on what to play, then do your best to win. Have fun!
                    </h5>

                </div><div>
                    <h1>You've Done It!</h1>
                    <div className="divider" />
                    <h4>
                        You're officially ready to play on the big screen!
                    </h4>
                    <h5>
                        Good luck, have fun, and don't be too disappointed if you don't win the trophy, there's always next round.
                    </h5>
                </div>
            </div>
            <div className="footer-container">

                <div>
                    <button onClick={() => window.location.href = "/help"} >Help</button>
                    <button onClick={() => window.location.href = "/terms-and-conditions"} >Terms & Conditions</button>
                    <button onClick={() => window.location.href = "/cookie-policy"} >Cookie Policy</button>
                    <button onClick={() => window.location.href = "/data-protection"} >Data Protection</button>
                    <button onClick={() => window.location.href = "/privacy-policy"} >Privacy Policy</button>
                </div>
                <img src={logo} alt="logo" />


            </div>
        </div>
    )
}
