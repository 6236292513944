import React, { Component } from 'react';
import './HostGameView.scss';

import StarImg from 'images/star.png';

export default class WhereInTheWorld extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            players: props.players,
            WhereInTheWorldData: props.WhereInTheWorldData,
            location: props.location,
            url: '',
        };
        this.canvasRef = React.createRef();
        this.mapImage = new Image();
        this.starImage = new Image();
    }

    componentDidMount() {
        const url = this.props.WhereInTheWorldData.filter(x => x)[1];
        this.setState({ url }, () => {
            this.updateMapImage(url);
        });

        this.setStarImage();
        
        window.addEventListener('resize', this.resizeCanvas);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeCanvas);
    }

    componentDidUpdate(prevProps) {
        this.resizeCanvas();

        if (prevProps.WhereInTheWorldData !== this.props.WhereInTheWorldData) {
            const url = this.props.WhereInTheWorldData && this.props.WhereInTheWorldData.filter(x => x)[1];
            // Only update if url is truthy (not null/undefined)
            if (url) {
                this.setState({ url }, () => {
                    this.updateMapImage(url);
                });
            } else {
                console.warn("No valid URL found in WhereInTheWorldData");
            }
        }

        //if (prevProps.players !== this.props.players) {
        //    this.setState({ players: this.props.players }, () => {
        //        requestAnimationFrame(() => this.drawCanvas());
        //    });
        //}
    }

    updateMapImage = (url) => {
        this.mapImage.src = url;
        this.mapImage.onload = () => {
            this.imageNaturalWidth = this.mapImage.width;
            this.imageNaturalHeight = this.mapImage.height;
            this.resizeCanvas();
            requestAnimationFrame(() => this.drawCanvas());
        };
        this.mapImage.onerror = () => {
            console.error('Failed to load map image:', url);
        };
    };

    setStarImage = () => {
        this.starImage.src = StarImg;
    }

    resizeCanvas = () => {
        const canvas = this.canvasRef.current;
        // generate random number from 1 to 10 to force canvas to update
        let displayWidth = window.innerWidth * 0.9;
        let displayHeight = window.innerHeight * 0.6;
        if (canvas) {
            canvas.style.width = `${displayWidth}px`;
            canvas.style.height = `${displayHeight}px`;

            canvas.width = displayWidth;
            canvas.height = displayHeight;

            this.drawCanvas();
        }
    };

    drawCanvas = () => {
        const canvas = this.canvasRef.current;
        if (!canvas) return;
        if (!this.mapImage || !this.mapImage.complete || this.mapImage.naturalWidth === 0 || this.mapImage.naturalHeight === 0) {
            // Image not loaded or broken, don't draw
            return;
        }

        const ctx = canvas.getContext('2d');
        const { width: canvasWidth, height: canvasHeight } = canvas;
       
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        const paddingX = 0;
        const paddingY = 0;
        const drawWidth = canvasWidth;
        const drawHeight = canvasHeight;

        const imageAspectRatio = this.mapImage.width / this.mapImage.height;
        const canvasAspectRatio = drawWidth / drawHeight;

        let finalWidth, finalHeight, offsetX, offsetY;

        if (imageAspectRatio > canvasAspectRatio) {
            finalWidth = drawWidth;
            finalHeight = drawWidth / imageAspectRatio;
            offsetX = paddingX;
            offsetY = paddingY + (drawHeight - finalHeight) / 2;
        } else {
            finalHeight = drawHeight;
            finalWidth = drawHeight * imageAspectRatio;
            offsetX = paddingX + (drawWidth - finalWidth) / 2;
            offsetY = paddingY;
        }

        this.offsetX = offsetX;
        this.offsetY = offsetY;
        this.finalWidth = finalWidth;
        this.finalHeight = finalHeight;


        ctx.drawImage(this.mapImage, offsetX, offsetY, finalWidth, finalHeight);

        if (this.props.roundOver) {
            const [x, y] = this.props.correctAnswer;
            console.log(`Correct Answer: ${x} ${y}`);
            const normalizedX = x / this.imageNaturalWidth;
            const normalizedY = y / this.imageNaturalHeight;

            const cx = normalizedX * this.finalWidth + this.offsetX;
            const cy = normalizedY * this.finalHeight + this.offsetY;
            const size = 40;
            ctx.shadowColor = "black";
            ctx.shadowBlur = 5;
            ctx.shadowOffsetX = 2.5;
            ctx.shadowOffsetY = 2.5;

            ctx.drawImage(this.starImage, cx - size / 2, cy - size / 2, size, size);

            const players = this.props.players || [];
            players.forEach(player => {
                const coords = player.ucData.whereInTheWorldCoords.filter(x => x);
                console.log(`${player.id} ${coords}`)

                if (coords && coords.length === 2) {
                    const [imageX, imageY] = coords;
                    const normalizedX = imageX / this.imageNaturalWidth;
                    const normalizedY = imageY / this.imageNaturalHeight;

                    const canvasX = normalizedX * this.finalWidth + this.offsetX;
                    const canvasY = normalizedY * this.finalHeight + this.offsetY;
                    // add drop shadow

                    ctx.shadowColor = "black";
                    ctx.shadowBlur = 5;
                    ctx.shadowOffsetX = 2.5;
                    ctx.shadowOffsetY = 2.5;

                    ctx.fillStyle = player.ucData.whereInTheWorldColor;
                    ctx.beginPath();
                    ctx.arc(canvasX, canvasY, player.ucData.answerCorrect ? 10 : 5, 0, 2 * Math.PI);
                    ctx.fill();
                }
            });
        }
    };

    render() {
        return (
            <div className="host-witw-container">
                <div className="map-canvas-holder"/>
                    <canvas ref={this.canvasRef} className="map-canvas" />

            </div>
        );
    }
}
