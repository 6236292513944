import React, { Component } from 'react';
import logo from './images/PlaceHolderLogo.png'


export default function DataProtection() {

    return (
        <div className="help-container">
            <div className="vignette-overlay" />

            <div className="scroll-container">
                <button className="back-button" onClick={() => window.location.href = "/"}>
                    Back
                </button>
                <h1>Play360 Ltd Is Committed To Protecting The Privacy Of Your Data</h1>
                <h5>
                   Play360 Ltd is registered as a data controller with the Information Commissioners Office.
                </h5>   <h5>
                    Play360 Ltd’s data resides with Microsoft Azure, a cloud based data centre. Azure leads the industry with the most comprehensive compliance coverage, enabling Play360 Ltd to meet all of our regulatory obligations giving our clients peace of mind that their data is safe and secure.
                </h5>   <h5>
                    All of your data is stored on secure servers and back-ups are made in real time.
                </h5>   <h5>
                   Play360 Ltde and Microsoft Azure actively protect your data.
                </h5>   <h5>
                    Play360 Ltd use TLS technology to ensure secure connections so only you can see your Information
                </h5>   <h5>
                    All passwords are encrypted and access to https://uctv.ai can only be given by the subscriber.
                </h5> 
            </div>
            <div className="footer-container">

                <div>
                    <button onClick={() => window.location.href = "/help"} >Help</button>
                    <button onClick={() => window.location.href = "/terms-and-conditions"} >Terms & Conditions</button>
                    <button onClick={() => window.location.href = "/cookie-policy"} >Cookie Policy</button>
                    <button onClick={() => window.location.href = "/data-protection"} >Data Protection</button>
                    <button onClick={() => window.location.href = "/privacy-policy"} >Privacy Policy</button>
                </div>
                <img src={logo} alt="logo" />


            </div>
        </div>
    )
}