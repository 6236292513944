import React, { Component, useState, useEffect } from 'react';

import './HostGameView.scss';
import ReactPlayer from 'react-player';

export default class LyricLinguist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playingVideo: false,
        };
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        // Initialize video and fake questions
        console.log("Lyric Linguist Component Mounted");
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            setTimeout(() => this.setState({
                playingVideo: true,
            }), 2000);
        }
    }

    handleEnded = () => {
        console.log("Video Ended");
        this.setState({ playingVideo: false });
        setTimeout(() => this.setState({ playingVideo: true }), 4000);
    };

    render() {

        const lyricData = this.props.LyricLinguistData.filter(x => x);
        console.log(lyricData)

        const video = lyricData[0];
        const answerPool = [lyricData[1], lyricData[2], lyricData[3]];

        return (
            <div className={`lyric-linguist-container ${this.props.show && "show"}`}>
                <div className="lyric-linguist-video-wrapper">
                    {
                        this.props.message !== "What's the connection?" && this.props.timer && this.props.timer !== 0 &&
                        <div className="lyric-linguist-timer">
                            <div className="lyric-linguist-timer-border">
                                {
                                    this.props.timer !== 0 && (<h2>{this.props.timer}</h2>)
                                }
                            </div>
                        </div>
                    }
                    <ReactPlayer
                        ref={this.videoPlayer}
                        url={video}
                        className="lyric-linguist-video"
                        width="100%"
                        height="100%"
                        onEnded={this.handleEnded}
                        playing={this.props.show && !this.props.showCorrectAnswer && this.state.playingVideo}
                        loop={false}
                    />
                </div>
                <MultipleChoice
                    answer={this.props.correctAnswer}
                    showCorrectAnswer={this.props.showCorrectAnswer}
                    answerPool={answerPool}
                />
            </div>
        );
    }
}

const MultipleChoice = ({ answer, showCorrectAnswer, answerPool }) => {
    return (
        <div className="multiple-choice-container">
            {
                answerPool.map((option, index) => (
                    <div className={`multiple-choice-option-container ${showCorrectAnswer && option === answer[0] ? "correct" : ""}`} >
                        <div key={index} className={`multiple-choice-option ${showCorrectAnswer && option === answer[0] ? "correct" : ""}`}>
                            <h1>{option}</h1>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};
