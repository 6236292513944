import React, { Component } from 'react';
import './SelectingTeam.scss';

export default class SelectingTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            team: null,
        }
    }

    selectTeam(team) {
        this.props.room.send("select_team", {
            data: {
                team: team
            }
        });
        this.setState({ team: team })
    }

    render() {

      

            return (
                <div className="selecting-team-container">
                    <div className="game-view-header-profile-icon2">
                        {
                            this.props.player.ucData.playerAvatar.length > 2 ?
                                <>
                                    <img src={this.props.player.ucData.playerAvatar} alt="Avatar" className="header-profile-image" />
                                </> :
                                <>
                                    <div className="header-profile-image" >
                                        <div class="circle-loading" />
                                    </div>
                                </>

                        }
                        <div className="game-view-header-profile-name">
                            <h3>
                                {this.props.player.name}
                            </h3>
                        </div>
                    </div>
                    {
                        !this.props.locked && 
                        <>
                            <h1>Please select your team</h1>
                            <div className="selecting-team-buttons">
                                <div className="team-container">
                                    <button onClick={() => this.selectTeam("a")} className={`${this.state.team === "a" ? "team-selected" : ""}`}>
                                        Team A
                                    </button>

                                </div>
                                <div className="team-container">
                                    <button onClick={() => this.selectTeam("b")} className={`${this.state.team === "b" ? "team-selected" : ""}`}>
                                        Team B
                                    </button>

                                </div>
                            </div>
                        </>
                    }                    
                </div>
            );
      
        
    }
}



