import React, { useState, useEffect } from 'react'
import './WebsiteLanding.scss'
import logo from './images/PlaceHolderLogo.png'

export default function WhatsNext() {

    return (
        <div className="whats-next">
            <div className="vignette-overlay" /> 

            <div className="scroll-container">
                <button className="back-button" onClick={() => window.location.href="/" }>
                    Back
                </button>
                <h1>Getting Started</h1>

                <ul>
                    <li><strong>1. Visit uctv.ai on a laptop, Smart TV, or iPad.</strong>
                    </li>
                    <li><strong>2. Scan the QR code with your phone to log in automatically.</strong>
                    </li>
                    <li><strong>3. Invite your friends to join and play!</strong>
                    </li>
                    <li><strong>Enjoy your game!</strong></li>
                </ul>

            </div>
            <div className="footer-container">

                <div>
                    <button onClick={() => window.location.href = "/help"} >Help</button>
                    <button onClick={() => window.location.href = "/terms-and-conditions"} >Terms & Conditions</button>
                    <button onClick={() => window.location.href = "/cookie-policy"} >Cookie Policy</button>
                    <button onClick={() => window.location.href = "/data-protection"} >Data Protection</button>
                    <button onClick={() => window.location.href = "/privacy-policy"} >Privacy Policy</button>
                </div>
                <img src={logo} alt="logo" />
            </div>
        </div>
    )
}
