import React, { useState, useEffect } from 'react'
import './WebsiteLanding.scss'
import logo from './images/PlaceHolderLogo.png'

export default function TermsConditions() {

    return (
        <div className="help-container">
            <div className="vignette-overlay" /> 

            <div className="scroll-container">
                <button className="back-button" onClick={() => window.location.href="/" }>
                    Back
                </button>
                <h1>Universally Challenged - Terms and Conditions</h1>
                <div>
                    <h2>
                        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OUR SERVICE.
                    </h2> <h3>
                        These terms of use (together with the documents referred to in it) tell you the terms of use on which you may make use of our Service, whether as a guest or a registered user. Use of our service includes accessing, browsing, or registering to use our service. Please read these terms of use carefully before you start to use our service, as these will apply to your use of our service. We recommend that you print a copy of this for future reference. By using our service, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms of use, you must not use our service.
                    </h3>
                </div> <div>
                    <h2>
                        Other applicable terms
                    </h2> <h3>
                        These terms of use refer to the following additional terms, which also apply to your use of our service: Our Privacy Policy, which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our service, you consent to such processing and you warrant that all data provided by you is accurate.
                    </h3>
                </div> <div>
                    <h2>
                        Information about us
                    </h2> <h3>
                        Our Service are operated by Play360 Ltd ("we" or “us”). We are registered in England and Wales under company number company number 13738096 and a registered address of 32 High Street, Guildford, Surrey, GU1 3EL, UK. We are a limited company.
                    </h3>
                </div> <div>
                    <h2>
                        Changes to these terms
                    </h2> <h3>
                        We may revise these terms of use at any time by amending this page. Please check this page from time to time to take notice of any changes we made, as they are binding on you.
                    </h3>
                </div> <div>
                    <h2>
                        Change to our Service
                    </h2> <h3>
                        We may update our service from time to time, and may change the content at any time. However, please note that any of the content on our service may be out of date at any given time, and we are under no obligation to update it. We do not guarantee that our Service, or any content on it, will be free from errors or omissions.
                    </h3>
                </div> <div>
                    <h2>
                        Accessing our Service
                    </h2> <h3>
                        Our services may require a subscription or one-off purchase to access all of the content. We do not guarantee that our service, or any content on it, will always be available or be uninterrupted. Access to our service is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our service without notice. We will not be liable to you if for any reason our service is unavailable at any time or for any period. You are responsible for making all arrangements necessary for you to have access to our service. You are also responsible for ensuring that all persons who access our service through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.
                    </h3>
                </div> <div>
                    <h2>
                        Your account and password
                    </h2> <h3>
                        If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party. We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use. If you know or suspect that anyone other than you knows your user identification code or password, you must promptly reset your identification code or password.
                    </h3>
                </div> <div>
                    <h2>
                        Intellectual property rights
                    </h2> <h3>
                        We are the owner or the licensee of all intellectual property rights in our service, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved. You must not use any part of the content on our service for commercial purposes without obtaining a licence to do so from us or our licensors. If you print off, copy or download any part of our service you are in breach of these terms of use, your right to use our service will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
                    </h3>
                </div> <div>
                    <h2>
                        No reliance on information
                    </h2> <h3>
                        The content on our Service is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any service on the basis of the content on our service. Although we make reasonable efforts to update the information on our service, we make no representations, warranties or guarantees, whether express or implied, that the content on our service is accurate, complete or up-to-date.
                    </h3>
                </div> <div>
                    <h2>
                        Limitation of our liability
                    </h2> <h3>
                        Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law. To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our service or any content on it, whether express or implied. We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: use of, or inability to use, our service; or use of or reliance on any content displayed on our service. If you are a business user, or a school, please note that in particular, we will not be liable for: loss of profits, sales, business, or revenue; business interruption; loss of anticipated savings; loss of business opportunity, goodwill or reputation; or any indirect or consequential loss or damage. If you are a student using a single subscription, please note that we only provide our service for domestic and private use. You agree not to use our service for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity. We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our service. Different limitations and exclusions of liability will apply to liability arising as a result of the supply of any goods or services by us to you, which will be set out in our Terms and conditions of supply.

                    </h3>
                </div> <div>
                    <h2>
                        Hacking

                    </h2> <h3>
                        You must not attempt to gain unauthorised access to our service, the server on which our service is stored or any server, computer or database connected to our service. You must not attack our service via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our service will cease immediately.
                    </h3>
                </div> <div>
                    <h2>
                        Linking to our Service
                    </h2> <h3>
                        You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link to our service in any website that is not owned by you. We reserve the right to withdraw linking permission without notice. If you wish to make any use of content on our service, please contact us.
                    </h3>
                </div> <div>
                    <h2>
                        Applicable law
                    </h2> <h3>
                        If you are a consumer, please note that these terms of use, its subject matter and its formation, are governed by English law. You and we both agree that the courts of England and Wales will have non-exclusive jurisdiction. However, if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland. If you are a business, or a school, these terms of use, its subject matter and its formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.
                    </h3>
                </div><div>
                    <h2>
                        Purchases
                    </h2> <h3>
                        All orders for products purchased through our website at https://uctv.ai (the “website”) accepted by us are subject to these terms and conditions of sale. No other terms will apply to the supply of products by us.

                        The images of the products on our website are for illustrative purposes only. Your product may vary slightly from those images.
                    </h3>
                </div><div>
                    <h2>
                        Prices
                    </h2> <h3>
                        The price of a product (which includes VAT) will be the price indicated on the order pages when you placed your order. We take all reasonable care to ensure that the price of the product advised to you is correct.
                    </h3>
                </div><div>
                    <h2>
                        Ordering
                    </h2> <h3>
                        The products for sale on the website are for personal non-commercial use only and must not be bought for resale or otherwise for business purposes. We will only accept orders from consumers for domestic use. If you are a business customer please contact us on to discuss how to order from us.

                        Our acceptance of your order will take place when we confirm that acceptance to you, at which point a contract will come into existence between you and us.

                        We reserve the right to decline to trade with any person. If we are unable to accept your order, we will inform you of this and will not charge you for the product. This might be because of unexpected limits on our resources which we could not reasonably plan for, because we have identified an error in the price or description of the product, or for any other reason.

                        We will assign an order number to your order and tell you what it is when we accept your order. It will help us if you can tell us the order number whenever you contact us about your order.
                    </h3>
                </div><div>
                    <h2>
                        Payment
                    </h2> <h3>
                        We accept payment with the payment methods detailed on the website. You must pay for the product(s) before we dispatch them.

                        If you think a payment, or request for payment, is wrong please contact us promptly to let us know.
                    </h3>
                </div><div>
                    <h2>
                        Cancelling an order
                    </h2> <h3>
                        Your right to cancel an order will depend on what you have bought, whether there is anything wrong with it, how we are performing and when you decide to end the contract. If you wish to make a change, please contact us. We will let you know if the change is possible. Contact us at info@play360.ai
                    </h3>
                </div>
            </div>
            <div className="footer-container">

                <div>
                    <button onClick={() => window.location.href = "/help"} >Help</button>
                    <button onClick={() => window.location.href = "/terms-and-conditions"} >Terms & Conditions</button>
                    <button onClick={() => window.location.href = "/cookie-policy"} >Cookie Policy</button>
                    <button onClick={() => window.location.href = "/data-protection"} >Data Protection</button>
                    <button onClick={() => window.location.href = "/privacy-policy"} >Privacy Policy</button>
                </div>
                <img src={logo} alt="logo" />


            </div>
        </div>
    )
}
