import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useSelector, useDispatch } from 'react-redux'
import { Elements, } from "@stripe/react-stripe-js";
import StripeService from "../services/stripe.service";
import Spinner from 'react-bootstrap/Spinner';

import CheckoutForm from "./Forms/CheckoutForm";
import FormPage from "./Utility/FormPage";
import { Container, Row, Form, } from "react-bootstrap";
import styles from "./CheckoutStyles.module.scss";

import UCLogo from "components/WebsiteLanding/images/PlaceHolderLogo.png";

const game = {
    name: "Universally Challenged",
    price: 9.99,
    quantity: 1,
    productId: process.env.REACT_APP_UC_PID,
}

export default function Checkout(props) {
    const [clientSecret, setClientSecret] = useState("");
    const [checkoutStep, setCheckoutStep] = useState(false);
    const [checkoutItems, setCheckoutItems] = useState([]);
    const [currency, setCurrency] = useState("");
    const [loadingCheckout, setLoadingCheckout] = useState(false);
    const [cartTotal, setCartTotal] = useState(0);
    const [stripePromise, setStripePromise] = useState(null);

    const [email, setEmail] = useState("");

    const { isLoggedIn, user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY));
        console.log("Looking for discount in redux");

        if (isLoggedIn) {
            console.log("setting user email : ", user.email);
            setEmail(user.email);
            generateCheckout(user.email);
        }

    }, []);

    const appearance = {
        //theme: 'flat',
        variables: {
            colorBackground: '#ececec',
        }
    };
    const options = {
        clientSecret,
        appearance,
        loader: "auto"
    };


    const generateCheckout = (userEmail) => {

        setLoadingCheckout(true);
        StripeService.createPaymentIntent(game, userEmail)
            .then((res) => {
                setLoadingCheckout(false);
                setCheckoutStep(true);
                console.log("res data ", res.data);
                setCartTotal(res.data.cartTotal);
                setClientSecret(res.data.clientSecret)
                setCheckoutItems(res.data.checkoutItems);
                setCurrency(res.data.currency);
            }).catch((err) => {
                console.log("Error : ", err);
                setLoadingCheckout(false);
            });
    }

    //const onChangeEmail = (e) => {
    //    const email = e.target.value;
    //    setEmail(email);
    //};

    //const isDev = () => { return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === 'development' }

    const getTotalSavings = () => {
        let amount = 0;
        checkoutItems.forEach((item) => {
            if (item.discountAmount) {
                amount += item.discountAmount;
            }
        });
        console.log(`total savings : ${amount}`)
        if (amount === 0) return null;
        else return `${currency}${(amount / 100).toFixed(2)}`;
    }


    const renderItemList = (item, title) => {
        return <table className={styles.checkoutTable}>
            <tr>
                <th>{title}</th>
                <th>Price</th>
                {
                    !checkoutStep &&
                    <th></th>
                }
            </tr>
            {
                renderItemRow(item)
            }
        </table>
    }

    const renderItemRow = (item) => {
        return <tr>
            <td>{item.name}</td>
            <td>
                {`${item.price}` || <Spinner animation="border" size="sm" />}{` `}
            </td>
        </tr>
    }

    const closeCheckout = () => {
        console.log("closing checkout");
        props.onClose()
    }

    return (
                <Container>
                    <Row>

                <div className={`${styles.checkoutForm}`}>
                    <button className={styles.backButton} onClick={closeCheckout}>
                        Back
                    </button>
                            <h2 className={styles.formTitle}>Purchase Game</h2>
                    <div className={styles.gameInfoContainer}>
                        <img src={UCLogo} alt="logo"></img>
                        <h3 style={{ fontSize: "2vh", width: "100%" }} >Start playing instantly. <br/> Universally Challenged Unlock for 30 Days for just £9.99</h3>
                     </div>
                            {/*{renderItemList(game, "Game")}*/}
                            {/*{*/}
                            {/*    checkoutStep &&*/}
                            {/*    <table className={styles.checkoutTable}>*/}
                            {/*        <tr className={styles.total}>*/}
                            {/*            <td className={styles.buffer}></td>*/}
                            {/*            <td className={styles.title}>Total</td>*/}
                            {/*            <td>{cartTotal} */}{/*{discountProducts == null || discountProducts.length == 0 && discount}*/}{/*</td>*/}
                            {/*        </tr>*/}
                            {/*        {*/}
                            {/*            getTotalSavings() != null &&*/}
                            {/*            <tr className={styles.totalSavings}>*/}
                            {/*                <td></td>*/}
                            {/*                <td>Total Savings</td>*/}
                            {/*                <td>{getTotalSavings()}</td>*/}
                            {/*                <td className={styles.bufferSmall}></td>*/}
                            {/*            </tr>*/}
                            {/*        }*/}
                            {/*    </table>*/}
                            {/*}*/}
                            {loadingCheckout && <Spinner animation="border" />}
                            {/*{*/}
                            {/*    !checkoutStep &&*/}
                            {/*    <>*/}
                            {/*        <Form onSubmit={continueToCheckout}>*/}
                            {/*            <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                            {/*                <Form.Label>Email address</Form.Label>*/}
                            {/*                <Form.Control disabled={loadingCheckout} type="email" placeholder="Enter email" required onChange={onChangeEmail} value={email} />*/}
                            {/*            </Form.Group>*/}
                            {/*            <button disabled={loadingCheckout} className={`${styles.button} ${styles.wide}`} type="submit" id="submit">{loadingCheckout ? <Spinner animation="border" /> : 'Checkout'}</button>*/}
                            {/*        </Form>*/}
                            {/*    </>*/}
                            {/*}*/}
                        </div>
                    </Row>
                    {
                        !loadingCheckout && checkoutStep &&
                        <Row xs={12}>
                            {clientSecret ?
                                <Elements options={options} stripe={stripePromise}>
                                    <CheckoutForm email={email} />
                                </Elements>
                                :
                                <Spinner animation="border" />
                            }
                        </Row>
                    }

                </Container>
            
    );
}