import React, { Component, useEffect, useState } from 'react';
import WordLetters from 'components/UserComponents/WordLetters';
import OnScreenKeyboard from 'components/UserComponents/OnScreenKeyboard';
import ConfirmButton from 'components/UserComponents/ConfirmButton';
import WordBox from 'components/UserComponents/WordBox';
import GridSelect from 'components/UserComponents/GridSelect';
import './GameView.scss';
import MultiChoice from 'components/UserComponents/MultiChoice';
import WhereInTheWorld from '../HostComponents/WhereInTheWorld';
export default class GameView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            player: props.player,
            players: props.players,
            answer: props.answer || '',
            userInput: '',
            round: props.round,
            timer: props.timer,
            showKeyboard: false,
            showConfirm: false,
            showWordLetters: false,
            showGridSelect: false,
            showWordBox: false,
            answerConfirmed: false,
            showPlayerList: false,
            showMultiChoice: false,
            selectedPlayerId: "",

            submitError: "",

            WhereInTheWorldData: this.props.WhereInTheWorldData
        };
    }

    handleKeyPress = (letter) => {
        const { answer, userInput } = this.state;
        const { round } = this.props;
        this.playClickSound();
        if (round === "missing_vowels") {
            const answerVowels = answer.match(/[aeiouAEIOU]/g);
            if (userInput.length === answerVowels.length) return;
            const isVowel = /[aeiouAEIOU]/.test(letter);

            if (isVowel) {
                const newUserInput = userInput + letter;
                this.setState({ userInput: newUserInput });
            }
        } else if (answer.length > userInput.length) {
            const nextCharInAnswer = answer[userInput.length + 1];

            let newUserInput = userInput + letter;

            if (nextCharInAnswer === ' ') {
                newUserInput += ' ';
            }

            this.setState({ userInput: newUserInput });
        }
    };

    playClickSound = () => {
        this.props.audio.sfxKeyboardClick1.loaded?.play();
    }

    playDeleteSound = () => {
        this.props.audio.sfxKeyboardClick2.loaded?.play();
    }

    playSubmitSound = () => {
        this.props.audio.sfxSubmit.loaded?.play();
    }


    handleUserInput = (data) => {
        this.setState({ userInput: data })
    }

    handleDelete = () => {
        const { userInput, answer } = this.state;
        this.playDeleteSound();
        if (userInput.length > 0) {
            let newUserInput = userInput.slice(0, -1);

            const nextCharInAnswer = answer[newUserInput.length];
            if (nextCharInAnswer === ' ') {
                newUserInput = newUserInput.slice(0, -1);
            }

            this.setState({ userInput: newUserInput });
        }
    };


    handleConfirm = () => {
        this.playSubmitSound();
        if (this.state.userInput.length === 0) {
            if (this.props.round === "face_off") this.setState({ submitError: "Please enter a celebrity/character name." });
            else this.setState({ submitError: "Please enter an answer." });
            return;
        } else if (typeof this.state.answer === "string") {
            console.log("answer is string");
            console.log("user input length : ", this.state.userInput.length);
            console.log("answer length : ", this.state.answer.length);
            if (this.state.userInput.length < this.state.answer.length && this.props.round !== "where_in_the_world" && this.props.round !== "missing_vowels") {
                this.setState({ submitError: "Please enter a full answer." });
                return;
            }
        }

        if (this.props.round === "face_off") {
            if (this.state.selectedPlayerId === "") {
                this.setState({ submitError: "Please select a player from the list above." });
                return;
            }
        }

        if (this.props.round === "missing_vowels") {
            let userInputArray = this.state.userInput.split('');
            let answerArray = this.state.answer.split('');

            let vowelIndex = 0;

            const fullAnswer = answerArray.map((char) => {
                if (/[aeiouAEIOU]/.test(char)) {
                    return userInputArray[vowelIndex++] || '_';
                }
                return char;
            }).join('');
            this.state.room.send("submit_answer", {
                answer: fullAnswer
            });
        } else if (this.props.round === "face_off") {
            this.setState({ selectedPlayerId: "" });
            this.state.room.send("submit_answer", {
                answer: this.state.userInput,
                selectedPlayerId: this.state.selectedPlayerId
            })
        } else {
            this.state.room.send("submit_answer", {
                answer: this.state.userInput
            })
        }


        this.setState({
            userInput: '',
            answerConfirmed: true,
        })

    };

    updateViewBasedOnRound = (round) => {
        let newState = {
            showKeyboard: false,
            showConfirm: false,
            showWordLetters: false,
            showGridSelect: false,
            showWordBox: false,
            showMultiChoice: false,
            showPlayerList: false,
            selectedPlayerId: "",
            submitError: "",
        };

        switch (round) {
            case 'connect_four':
            case 'missing_vowels':
                newState = { ...newState, showKeyboard: true, showWordLetters: true, showConfirm: true };
                break;
            case 'where_in_the_world':
                newState = { ...newState, showGridSelect: true, showConfirm: true, };
                break;
            case 'spelling_bee':
            case 'picture_frame':
            case 'face_off':
                newState = { ...newState, showKeyboard: true, showWordLetters: true, showConfirm: true, showPlayerList: true, };
                break;
            case 'scene_it':
                newState = { ...newState, showKeyboard: true, showWordLetters: true, showConfirm: true };
                break;
            case 'lyric_linguist':
                newState = { ...newState, showMultiChoice: true }
                break;
            default:
                break;
        }

        this.setState(newState);
    };

    componentDidMount() {
        this.updateViewBasedOnRound(this.state.round);
        this.updateAnswer();
    }

    componentDidUpdate(prevProps) {
        if (this.props.round !== prevProps.round) {
            this.updateViewBasedOnRound(this.props.round);
        }

        if (this.props.intermission !== prevProps.intermission) {
            if (!this.props.intermission) {
                this.setState({ answerConfirmed: false })
            }
        }

        if (prevProps.WhereInTheWorldData !== this.props.WhereInTheWorldData) {
            this.setState({ WhereInTheWorldData: this.props.WhereInTheWorldData })
        }

        if (JSON.stringify(prevProps.correctAnswer) !== JSON.stringify(this.props.correctAnswer)) {
            this.updateAnswer();
        }

        if (prevProps.timer !== this.props.timer) {
            if (this.props.timer === 1) {
                this.handleConfirm()
            }
        }
    }

    updateAnswer() {

        const correctAnswer = Array.isArray(this.props.correctAnswer)
            ? this.props.correctAnswer.filter(x => x).join('')
            : this.props.correctAnswer;

        this.setState({ answer: correctAnswer, userInput: '', submitError: "" });
    }

    getRoundDescription() {
        const { round } = this.props;
        let roundDescription;

        switch (round) {
            case 'connect_four':
                roundDescription = 'Buzz in when you know the connection...';
                break;
            case 'missing_vowels':
                roundDescription = 'Fill in the missing vowels.';
                break;
            case 'where_in_the_world':
                roundDescription = `${this.props.WhereInTheWorldData.filter(x => x)[0]}`;
                break;
            case 'lyric_linguist':
                roundDescription = 'What song are the lyrics from';
                break;
            case 'spelling_bee':
                roundDescription = 'Spell the word you just heard.';
                break;
            case 'picture_frame':
                roundDescription = 'The name of the film is...';
                break;
            case 'face_off':
                roundDescription = 'Who\'s the celebrity?';
                break;
            case 'scene_it':
                roundDescription = 'The film depicted is...';
                break;
            default:
                break;

        }

        return roundDescription;
    }

    renderTextDiv(text, additionalStyles, additionalTextStyles) {
        return (
            <div className={`bold-text-outline ${additionalStyles}`}>
                {text}
                <div className={`bold-text ${additionalTextStyles}`}>{text}</div>
            </div>
        );
    }

    getIntermissionView() {

        const isPlayerCorrect = this.props.player.ucData.answerCorrect;

        let correctText = "CORRECT!";
        let incorrectText = "INCORRECT!";

        //TODO: Fix this!

        if (this.props.round === "where_in_the_world") {
            correctText = "YOU'RE CLOSE!";
            incorrectText = "TOO FAR AWAY!";
        }

        let intermissionText = this.state.answerConfirmed ? isPlayerCorrect ? correctText : incorrectText : 'TOO SLOW';       
        let intermissionTextStyles = this.state.answerConfirmed ? isPlayerCorrect ? 'correct-bold-styles' : 'incorrect-bold-styles' : 'incorrect-bold-styles';

        if (this.props.getReady) {
            intermissionText = "Get Ready";
            intermissionTextStyles = "";
        }

        return <div className="intermission-container">
            {this.state.player.ucData.answerCorrect && <Confetti />}
            <div className="absolute-double-text-display">
                {this.renderTextDiv(intermissionText, '', intermissionTextStyles)}
            </div>
        </div>
    }

    getWaitingView() {
        return <div className="intermission-container">
            <div className="game-view-header-profile">
                <div className="game-view-header-profile-icon2">

                    {
                        this.state.player.ucData.playerAvatar.length > 2 ?
                            <img src={this.state.player.ucData.playerAvatar} alt="Avatar" className="header-profile-image" />
                            :
                            <div className="header-profile-image" >
                                <div class="circle-loading" />
                            </div>

                    }
                    <div className="game-view-header-profile-name">
                        <h3>
                            {this.state.player.name}
                        </h3>
                    </div>
                </div>

            </div>
        </div>
    }

    getPlayingView() {
        if (this.props.answerType === 1) {
            const buzzedInPlayerId = this.props.buzzedInPlayerId;
            if (buzzedInPlayerId === this.props.player.id) {
                return this.getQuestionView();
            } else {
                return this.getBuzzerView();
            }
        } else if (this.props.answerType === 2) {
            return this.getQuestionView();
        }
    }

    

    clickBuzzer() {
        this.props.room.send("uc_buzz_in", {});
    }

    getBuzzerView() {
        const team = this.props.player.ucData.team;
        const buzzedInPlayerId = this.props.buzzedInPlayerId;
        const lockedOut = (team === "a" && this.props.teamALocked) || (team === "b" && this.props.teamBLocked);
        let buzzedInPlayer;
        if (buzzedInPlayerId.length > 0) {           

            buzzedInPlayer = this.props.players.find(x => x.id === buzzedInPlayerId);
        }

        return <div className="game-view-container">
            <div className="game-view-header">
                <div className="game-view-header-profile">
                    <div className="game-view-header-profile-icon">
                        {
                            this.state.player.ucData.playerAvatar.length > 2 ?
                                <>
                                    <img src={this.state.player.ucData.playerAvatar} alt="Avatar" className="header-profile-image" />
                                </> :
                                <>
                                    <div className="header-profile-image" >
                                        <div class="circle-loading" />
                                    </div>
                                </>

                        }
                        <div className="game-view-header-profile-name">
                            <h3>
                                {this.state.player.name}
                            </h3>
                        </div>
                    </div>
                    {
                        this.state.showPlayerList === false &&
                        <h1 className="round-description">{this.getRoundDescription()}</h1>
                    }
                </div>
            </div>
            <div className="game-view-content-container">
                <div className={`buzzer`}>
                    {/*<div className={styles.buzzerText}>BUZZ IN</div>*/}
                    <div className={`buzzerOuter`}>
                        <button onClick={() => this.clickBuzzer()} disabled={buzzedInPlayer != null || lockedOut}>
                            <div className={`lockedMessage`}>LOCKED</div>
                            {/*<div className={`${styles.cooldown} ${team?.cooldownRunning && team?.cooldown && styles.show}`}>{Math.max(0, team?.cooldown)}s</div>*/}
                        </button>
                    </div>
                    {
                        lockedOut ?
                            <div className={`buzzedInPlayer show`}>
                                Your team is locked out!
                            </div>
                            :
                            <div className={`buzzedInPlayer ${buzzedInPlayer && "show"}`}>
                                {`${buzzedInPlayer?.name} has buzzed in`}
                            </div>
                    }

                </div>
            </div>
        </div>
    }

    handleMultiChoiceAnswer(answer) {
        this.playSubmitSound();
        this.setState({
            answerConfirmed: true,
        })
        this.state.room.send("submit_answer", {
            answer: answer
        });
    }

    getQuestionView() {
        return <div className="game-view-container">
            <div className="game-view-header">
                {
                    this.props.isBuzzTimer ?
                        <div className={`client-toaster-timer`}>
                            <div className="client-toaster-timer-border">
                                {
                                    this.props.timer !== 0 && (<h2>{this.props.buzzTimer}</h2>)
                                }
                            </div>
                        </div>
                        :
                        this.props.round !== "face_off" &&
                        <div className="game-view-header-profile">
                            <div className="game-view-header-profile-icon">
                                {
                                    this.state.player.ucData.playerAvatar.length > 2 ?
                                        <>
                                            <img src={this.state.player.ucData.playerAvatar} alt="Avatar" className="header-profile-image" />
                                        </> :
                                        <>
                                            <div className="header-profile-image" >
                                                <div class="circle-loading" />
                                            </div>
                                        </>

                                }
                                <div className="game-view-header-profile-name">
                                    <h3>
                                        {this.state.player.name}
                                    </h3>
                                </div>
                            </div>
                            {
                                this.state.showPlayerList === false &&
                                <h1 className="round-description">{this.getRoundDescription()}</h1>
                            }
                        </div>
                }
            </div>
            {
                this.state.showPlayerList &&
                <>
                    <h2 className="round-description">Whose face is this?</h2>
                    <h4 className="round-sub-text">(Select one player below)</h4>
                    <div className="client-selecting-player-wrapper">
                        <div className={`host-selecting-team-player-container player-container-grid${this.props.players.length}`}>
                            {this.props.players.map(player => (
                                <div className="player-icon-container" onClick={() => this.setState({ selectedPlayerId: player.id })}>
                                    <div className={`player-icon ${player.id === this.state.selectedPlayerId && "highlight"}`}>
                                        {
                                            player.ucData.playerAvatar.length > 2 ?
                                                <>
                                                    <img src={player.ucData.playerAvatar} className="player-icon-image" alt="Av" />

                                                </> :
                                                <>
                                                    <div className="header-profile-image" >
                                                        <div class="circle-loading2" />
                                                    </div>
                                                </>

                                        }
                                        <div className={`player-icon-name-container ${player.id === this.state.selectedPlayerId && "highlight"}`}>
                                            <h3 key={player.id}>{player.name}</h3>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                </>
            }

            <div className="game-view-content-container">
                {
                    this.state.showPlayerList === true &&
                    <h2 className="round-description mb-none">{this.getRoundDescription()}</h2>
                }
                {
                    this.state.showWordLetters && (
                        <WordLetters
                            answer={this.state.answer}
                            userInput={this.state.userInput}
                            round={this.props.round}

                        />
                    )
                }
                {
                    this.state.showWordBox && (
                        <WordBox
                            answer={this.state.answer}
                            userInput={this.state.userInput}
                            round={this.state.round}
                        />
                    )
                }
                {
                    this.state.showGridSelect && (
                        <GridSelect
                            WhereInTheWorldData={this.state.WhereInTheWorldData}
                            PlayerData={this.state.player}
                            handleUserInput={this.handleUserInput}
                            onKeyPress={this.handleKeyPress}
                        />
                    )
                }
                {
                    this.state.showConfirm && (
                        <ConfirmButton
                            onConfirm={this.handleConfirm}
                        />
                    )
                }
                {
                    this.state.showMultiChoice && (
                        <MultiChoice
                            userInput={(selectedAnswer) => this.handleMultiChoiceAnswer(selectedAnswer)}
                            answer={this.state.answer }
                            LyricLinguistData={this.props.LyricLinguistData}
                        />
                    )
                }
                {
                    this.state.submitError.length > 0 &&
                    <div className="submit-error">
                            <div className="close-button" onClick={() => this.setState({submitError: ""})}>X</div>
                            {this.state.submitError}
                        </div>
                }
            </div>
            <div className="keyboard-absolute-container">
                {
                    this.state.showKeyboard && (
                        <OnScreenKeyboard
                            onKeyPress={this.handleKeyPress}
                            onDelete={this.handleDelete}
                            round={this.props.round}
                        />
                    )
                }
            </div>
        </div>
    }

    render() {

        return (
            this.props.roundOver ?
                this.getIntermissionView()
                :
                this.props.player.ucData.hasSubmittedAnswer || this.props.intermission ?
                    this.getWaitingView()
                    :
                    this.getPlayingView()
        );
    }
}




const Confetti = () => {
    useEffect(() => {
        const generateParticles = () => {
            const container = document.querySelector(".confetti-container");
            if (!container) return;

            const confettiCount = (window.innerWidth / 120) * 10; // Particles based on screen width
            for (let i = 0; i <= confettiCount; i++) {
                const particle = document.createElement("span");
                particle.className = `particle c${Math.floor(Math.random() * 3) + 1}`;
                particle.style.top = `-${Math.random() * 10}%`; // Start slightly above the viewport
                particle.style.left = `${Math.random() * 100}%`; // Random horizontal position
                particle.style.width = `${Math.random() * 8 + 12}px`; // Larger width
                particle.style.height = `${Math.random() * 4 + 8}px`; // Larger height
                particle.style.animationDelay = `${Math.random() * 3}s`; // Random animation delay

                container.appendChild(particle);
            }
        };

        generateParticles();
    }, []);

    return <div className="confetti-container"></div>;
};