//music
import BgMusic from '../audio/bg_music.mp3';
import roundMusic from '../audio/sfx/RoundTestMusic.mp3'
//vo
import voGetIntoTeams from '../audio/vo/intro/get_into_teams.mp3';
import voConnectFourIntro from '../audio/vo/connect four/connect_four_intro.mp3';
import voFaceOffIntro from '../audio/vo/face swap/face_off_intro.mp3';
import voWhereInTheWorldIntro from '../audio/vo/where in the world/WITW_intro.mp3';
import voLyricLinguistIntro from '../audio/vo/lyrical linguist/lyrical_linguist_intro.mp3';
import voMissingVowelsIntro from '../audio/vo/missing vowels/missing_vowels_intro.mp3';
import voSceneItIntro from '../audio/vo/movies by monet/movies_by_monet_intro.mp3';
import voTimesUp from '../audio/vo/times_up.mp3';
import voTeamsSelected from '../audio/vo/teams_selected.mp3';
import voMainIntro from '../audio/vo/main_intro.mp3';
import voTeamAWins from '../audio/vo/ending/team_a_wins.mp3';
import voTeamBWins from '../audio/vo/ending/team_b_wins.mp3';
import voItsATie from '../audio/vo/ending/its_a_tie.mp3';

//sfx
import RoundIntroSFX from '../audio/RoundIntro.mp3';
import StageTimerSFX from '../audio/StageTimer.mp3';
import sfxPass from '../audio/sfx/Pass.mp3';
import sfxButton1 from '../audio/sfx/Button1.mp3';
import sfxButton2 from '../audio/sfx/Button2.mp3';
import sfxClick1 from '../audio/sfx/Click1.mp3';
import sfxPling from '../audio/sfx/Pling.mp3';
import sfxEnterWhoosh from '../audio/sfx/EnterWhoosh.mp3';
import sfxExitWhoosh from '../audio/sfx/ExitWhoosh.mp3';
import sfxCorrectAnswer1 from '../audio/sfx/CorrectAnswer1.mp3';
import sfxCorrectAnswer2 from '../audio/sfx/CorrectAnswer2.mp3';
import sfxCorrectAnswer3 from '../audio/sfx/CorrectAnswer3.mp3';
import sfxIncorrectAnswer1 from '../audio/sfx/IncorrectAnswer1.mp3';
import sfxClockTicking from '../audio/sfx/ClockTickingShort.mp3';
import sfxTimesUp from '../audio/sfx/TimesUp.mp3';
import sfxGlitch1 from '../audio/sfx/Glitch1.mp3';
import sfxGlitch2 from '../audio/sfx/Glitch2.mp3';
import sfxSlam from '../audio/sfx/Slam3.mp3';
import sfxZoomOut from '../audio/sfx/ZoomOut.mp3';
import sfxEntrySting from '../audio/sfx/EntrySting.mp3';
import connectFourSwoosh from '../audio/sfx/SwooshClipped.mp3'
import sfxLevelUp from '../audio/sfx/Levelup.mp3'
import sfxBuzzedIn from '../audio/sfx/BuzzedIn.mp3'

import incorrectShow from '../audio/sfx/Bassy Incorrect.mp3'
import correctShow from '../audio/sfx/Score Appears.mp3'
var audio = {
    //music
    BgMusic: { import: BgMusic, loaded: null, volume: 0.4, loop: true },
    roundTestMusic: { import: roundMusic, loaded: null, volume: 0.15, loop: true },

    //vo
    voMainIntro: { import: voMainIntro, loaded: null, volume: 1, loop: false },
    voGetIntoTeams: { import: voGetIntoTeams, loaded: null, volume: 1, loop: false },
    voConnectFourIntro: { import: voConnectFourIntro, loaded: null, volume: 1, loop: false },
    voWhereInTheWorldIntro: { import: voWhereInTheWorldIntro, loaded: null, volume: 1, loop: false },
    voFaceOffIntro: { import: voFaceOffIntro, loaded: null, volume: 1, loop: false },
    voLyricLinguistIntro: { import: voLyricLinguistIntro, loaded: null, volume: 1, loop: false },
    voMissingVowelsIntro: { import: voMissingVowelsIntro, loaded: null, volume: 1, loop: false },
    voTimesUp: { import: voTimesUp, loaded: null, volume: 1, loop: false },
    voTeamsSelected: { import: voTeamsSelected, loaded: null, volume: 1, loop: false },
    voSceneItIntro: { import: voSceneItIntro, loaded: null, volume: 1, loop: false },

    voTeamAWins: { import: voTeamAWins, loaded: null, volume: 1, loop: false },
    voTeamBWins: { import: voTeamBWins, loaded: null, volume: 1, loop: false },
    voItsATie: { import: voItsATie, loaded: null, volume: 1, loop: false },

    //sfx
    sfxPass: { import: sfxPass, loaded: null, volume: 1, loop: false },
    sfxClick1: { import: sfxClick1, loaded: null, volume: 1, loop: false },
    sfxButton1: { import: sfxButton1, loaded: null, volume: 1, loop: false },
    sfxButton2: { import: sfxButton2, loaded: null, volume: 1, loop: false },
    sfxPling: { import: sfxPling, loaded: null, volume: 1, loop: false },
    sfxEnterWhoosh: { import: sfxEnterWhoosh, loaded: null, volume: 1, loop: false },
    sfxExitWhoosh: { import: sfxExitWhoosh, loaded: null, volume: 1, loop: false },
    sfxCorrectAnswer1: { import: sfxCorrectAnswer1, loaded: null, volume: 1, loop: false },
    sfxCorrectAnswer2: { import: sfxCorrectAnswer2, loaded: null, volume: 1, loop: false },
    sfxCorrectAnswer3: { import: sfxCorrectAnswer3, loaded: null, volume: 1, loop: false },
    sfxIncorrectAnswer1: { import: sfxIncorrectAnswer1, loaded: null, volume: 1, loop: false },
    sfxClockTicking: { import: sfxClockTicking, loaded: null, volume: 1, loop: false },
    sfxTimesUp: { import: sfxTimesUp, loaded: null, volume: 1, loop: false },
    RoundIntroSFX: { import: RoundIntroSFX, loaded: null, volume: 0.15, loop: true },
    StageTimerSFX: { import: StageTimerSFX, loaded: null, volume: 0.2, loop: false },

    sfxGlitch1: { import: sfxGlitch1, loaded: null, volume: 1, loop: false },
    sfxGlitch2: { import: sfxGlitch2, loaded: null, volume: 1, loop: false },
    sfxSlam: { import: sfxSlam, loaded: null, volume: 0.75, loop: false },
    sfxZoomOut: { import: sfxZoomOut, loaded: null, volume: 1, loop: false },
    sfxEntrySting: { import: sfxEntrySting, loaded: null, volume: 1, loop: false },
    sfxConnectFourSwoosh: { import: sfxGlitch1, loaded: null, volume: 1, loop: false },
    sfxLevelUp: { import: sfxLevelUp, loaded: null, volume: 1, loop: false },
    sfxBuzzedIn: {import: sfxBuzzedIn, loaded: null, volume: 1, loop: false},
    sfxIncorrectShow: { import: incorrectShow, loaded: null, volume: 0.45, loop: false },
    sfxCorrectShow: {import: correctShow, loaded: null, volume:0.7, loop: false}
};

export {
    audio,
}