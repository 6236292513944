import React, { useState, useEffect } from 'react'
import './WebsiteLanding.scss'
import logo from './images/PlaceHolderLogo.png'

export default function PrivacyPolicy() {

    return (
        <div className="help-container">
            <div className="vignette-overlay" />

            <div className="scroll-container">
                <button className="back-button" onClick={() => window.location.href = "/"}>
                    Back
                </button>
                <h1>Play360 Ltd Privacy Policy</h1>
                <div>
                    <h3>
                        Welcome to Play360 Ltd privacy policy. Play360 Ltdis a company limited by shares incorporated in England and Wales with registered number 13738096 (referred to as “Play360 Ltd”, “Play360”, “we”, “us”). Play360 Ltd respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data, including when you visit our website (regardless of where you visit it from) at https://uctv.ai  and tells you about your privacy rights and how the law protects you.ure reference. By using our service, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms of use, you must not use our service.
                    </h3>
                </div> <div>
                    <h2>
                        Important Information And Who We Are
                    </h2> <h3>
                        This privacy policy aims to give you information on how Play360 Ltd collects and processes your personal data through your use of the uctv.ai website, including any data you may provide through this website when you sign up to our product and service. It is important that you read this privacy policy together with any other fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. Play360 Ltd is the controller and responsible for your personal data. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact us using the details set out below.
                    </h3>
                </div> <div>
                    <h2>
                        Contact Details
                    </h2> <h3>
                        Our details are: Play360 Ltd, email: info@play360.ai If you have any complaints or queries about matters affecting your privacy, or any other general data protection matters, then please do let us know by contacting us as set out above and we will endeavour to resolve the problem. In any event you have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues. The ICO can be contacted on their helpline number which is 0303 123 1113 between 9am and 5pm Monday to Friday, or by other contact methods as set out on their website. Please go to their website at www.ico.org.uk for more information.
                    </h3>
                </div> <div>
                    <h2>
                        Changes To The Privacy Policy And Your Duty To Inform Us Of Changes
                    </h2> <h3>
                        If you require previous versions of our privacy policies, please contact us. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.
                    </h3>
                </div> <div>
                    <h2>
                        Change to our Service
                    </h2> <h3>
                        We may update our service from time to time, and may change the content at any time. However, please note that any of the content on our service may be out of date at any given time, and we are under no obligation to update it. We do not guarantee that our Service, or any content on it, will be free from errors or omissions.
                    </h3>
                </div> <div>
                    <h2>
                        Third-Party Links
                    </h2> <h3>
                        This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.
                    </h3>
                </div> <div>
                    <h2>
                        The Data We Collect About You
                    </h2>
                    <h3>
                        Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). We may collect, use, store and transfer different kinds of personal data about you which we have grouped together
                    </h3>
                    <h5>
                        Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.
                    </h5> <h5>
                        Contact Data includes billing address, delivery address, email address and telephone numbers.
                    </h5> <h5>
                        Financial Data includes bank account and payment card detail.
                    </h5> <h5>
                        Transaction Data includes details about payments to and from you and other details of products and services you have purchased from us.
                    </h5> <h5>
                        Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.
                    </h5> <h5>
                        Profile Data includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.
                    </h5> <h5>
                        Usage Data includes information about how you use our website, products and services.
                    </h5><h5>
                        Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.
                    </h5><h4>
                        We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy. We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.
                    </h4>
                </div> <div>
                    <h2>
                        How Is Your Personal Data Collected?
                    </h2> <h3>
                        We use different methods to collect data from and about you including through:
                    </h3>
                    <h4>
                        Direct Interactions
                    </h4>
                    <h3>
                        You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:
                    </h3>
                    <h5>
                        apply for our products or services
                    </h5>    <h5>
                        create an account on our website
                    </h5>    <h5>
                        subscribe to our service or publications
                    </h5>    <h5>
                        request marketing to be sent to you
                    </h5>    <h5>
                        enter a competition, promotion or survey
                    </h5>
                    <h5>
                        give us some feedback
                    </h5>
                    <h4>
                        Automated Technologies Or Interactions
                    </h4>
                    <h5>
                        As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. Please see our cookie policy for further details.
                    </h5>
                    <h4>
                        Third Parties Or Publicly Available Sources
                    </h4>
                    <h3>
                        We may receive personal data about you from various third parties and public sources such as those set out below:
                    </h3>
                    <h5>
                        Technical Data from analytics providers such as Google
                    </h5><h5>
                        Contact, Financial and Transaction Data from providers of technical, payment and delivery services
                    </h5><h5>
                        Identity and Contact Data from data brokers or aggregators and from publicly availably sources such as Companies House and the social media
                    </h5>
                </div> <div>
                    <h2>
                        How We Use Your Personal Data
                    </h2>
                    <table class="table table-bordered">
                        <tr>
                            <th>Purpose / Activity</th>
                            <th>Type of Data</th>
                            <th>Lawful basis for processing including basis of legitimate interest</th>
                        </tr>
                        <tr>
                            <td>To register you as a new customer</td>
                            <td>
                                a. Identity<br />
                                b. Contact
                            </td>
                            <td>Performance of a contract with you</td>
                        </tr>
                        <tr>
                            <td>To process and deliver your order including:<br />
                                a. Manage payments, fees and charges<br />
                                b. Collect and recover money owed to us
                            </td>
                            <td>
                                a. Identity<br />
                                b. Contact<br />
                                c. Financial<br />
                                d. Transaction<br />
                                e. Marketing and Communications
                            </td>
                            <td>
                                a. Performance of a contract with you<br />
                                b. Necessary for our legitimate interest (to recover debts due to us)
                            </td>
                        </tr>
                        <tr>
                            <td>To manage our relationship with you which will include:<br />
                                a. Notifying you about changes to our terms or privacy policy<br />
                                b. Asking you to leave a review or take a survey
                            </td>
                            <td>
                                a. Identity<br />
                                b. Contact<br />
                                c. Profile<br />
                                d. Marketing and Communications
                            </td>
                            <td>
                                a. Performance of a contract with you<br />
                                b. Necessary to comply with a legal obligation<br />
                                c. Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)
                            </td>
                        </tr>
                        <tr>
                            <td>To enable you to partake in a prize draw, competition or complete a survey</td>
                            <td>
                                a. Identity<br />
                                b. Contact<br />
                                c. Profiles<br />
                                d. Usage<br />
                                e. Marketing and Communications
                            </td>
                            <td>
                                a. Performance of a contract with you<br />
                                b. Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)
                            </td>
                        </tr>
                        <tr>
                            <td>To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                            <td>
                                a. Identity<br />
                                b. Contact<br />
                                c. Technical
                            </td>
                            <td>
                                a. Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)<br />
                                b. Necessary to comply with a legal obligation
                            </td>
                        </tr>
                        <tr>
                            <td>To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</td>
                            <td>
                                a. Identity<br />
                                b. Contact<br />
                                c. Profile<br />
                                d. Usage<br />
                                e. Marketing and Communications<br />
                                f. Technical
                            </td>
                            <td>
                                Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)
                            </td>
                        </tr>
                        <tr>
                            <td>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</td>
                            <td>
                                a. Technical<br />
                                b. Usage
                            </td>
                            <td>
                                Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)
                            </td>
                        </tr>
                        <tr>
                            <td>To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
                            <td>
                                a. Identity<br />
                                b. Contact<br />
                                c. Technical<br />
                                d. Usage<br />
                                e. Profile
                            </td>
                            <td>
                                Necessary for our legitimate interests (to develop our products/services and grow our business)
                            </td>
                        </tr>
                    </table>




                </div> <div>
                    <h2>
                        Marketing
                    </h2> <h3>
                        We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. If you ever have any queries please do contact us. We will always give you an option to unsubscribe to receiving marketing data.
                    </h3>
                </div> <div>
                    <h2>
                        Promotional Offers From Us

                    </h2> <h3>
                        We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing). You will receive marketing communications from us if you have requested information from us or purchased goods or services from us or if you provided us with your details when you entered a competition or registered for a promotion and, in each case, you have not opted out of receiving that marketing. </h3>
                </div> <div>
                    <h2>
                        Third-Party Marketing
                    </h2> <h3>
                        We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.
                    </h3>
                </div> <div>
                    <h2>
                        Opting Out
                    </h2> <h3>
                        You can ask us or third parties to stop sending you marketing messages at any time by contacting us at any time. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, product/service experience or other transactions.</h3>
                </div><div>
                    <h2>
                        Cookies
                    </h2> <h3>
                        You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see our Cookie Policy. For more information on cookies generally please visit: www.allaboutcookies.org
                    </h3>
                </div><div>
                    <h2>
                        Change Of Purpose
                    </h2> <h3>
                        We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so. Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
                    </h3>
                </div><div>
                    <h2>
                        Disclosures Of Your Personal Data
                    </h2> <h3>
                        We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.

                    </h3>
                    <h5>
                        External Third Parties as set out in the Glossary
                    </h5>
                    <h5>
                        Specific third parties listed in the table in paragraph 4 above
                    </h5>
                    <h5>
                        Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy
                    </h5>
                    <h3>
                        We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
                    </h3>
                </div><div>
                    <h2>
                        International Transfers
                    </h2> <h3>
                        We do not transfer your personal data outside the European Economic Area (EEA).
                    </h3>
                </div><div>
                    <h2>
                        Data Security
                    </h2> <h3>
                        We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</h3>
                </div><div>
                    <h2>
                        Data Retention
                    </h2> <h3>
                        How long will you use my personal data for? We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements. Details of retention periods for different aspects of your personal data are available from us by contacting us. By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers for tax purposes. In some circumstances you can ask us to delete your data: see Request erasure below for further information. In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further policy to you. </h3>
                </div><div>
                    <h2>
                        Your Legal Rights
                    </h2> <h3>
                        Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please click on the links below to find out more about these rights: You have the right to:</h3>
                    <h3>
                        Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it
                        Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us
                        Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request
                        Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms
                        Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it
                        Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you
                        Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent. If you wish to exercise any of the rights set out above, please contact us
                    </h3>
                </div><div>
                    <h2>
                        No Fee Usually Required
                    </h2> <h3>
                       You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances. </h3>
                </div><div>
                    <h2>
                        What We May Need From You
                    </h2> <h3>
                        We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response. </h3>
                </div><div>
                    <h2>
                        Time Limit To Respond
                    </h2> <h3>
                        We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</h3>
                </div><div>
                    <h2>
                        LAWFUL BASIS
                    </h2> <h3>
                        Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us. Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract. Comply with a legal or regulatory obligation means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to. </h3>
                </div><div>
                    <h2>
                        THIRD PARTIES
                    </h2> <h3>
                        External Third Parties

                        Service providers acting as processors who provide IT and system administration services
                        Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, insurance and accounting services
                        HM Revenue & Customs, regulators and other authorities acting as processors or joint controllers based in the United Kingdom who require reporting of processing activities in certain circumstances
Where we use third parties for marketing we will ensure we have complied with all relevant legislation </h3>
                </div>
            </div>
            <div className="footer-container">

                <div>
                    <button onClick={() => window.location.href = "/help"} >Help</button>
                    <button onClick={() => window.location.href = "/terms-and-conditions"} >Terms & Conditions</button>
                    <button onClick={() => window.location.href = "/cookie-policy"} >Cookie Policy</button>
                    <button onClick={() => window.location.href = "/data-protection"} >Data Protection</button>
                    <button onClick={() => window.location.href = "/privacy-policy"} >Privacy Policy</button>
                </div>
                <img src={logo} alt="logo" />


            </div>
        </div>
    )
}
