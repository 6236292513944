import React, { Component } from 'react';
import './GameView.scss'; // Import your SCSS file

export default class MultiChoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            correctAnswer: props.answer,
            LyricLinguistData: props.LyricLinguistData || [],
        };
     
    }

    render() {
        let lyricData = this.props.LyricLinguistData.filter(x => x);
        let answersPool = [lyricData[1], lyricData[2], lyricData[3]];
        return (
            <div className="user-multi-choice-container">
                {
                    answersPool.map((answer, index) => (
                        <div className="user-multi-choice-answer-container">
                            <div key={index} className="user-multi-choice-answer" onClick={() => this.props.userInput(answer)}>
                                {answer}
                            </div>
                        </div>
                       
                    ))
                }
            </div>
        );
    }
}
